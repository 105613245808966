import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { getStats } from "../../../SF";
import "../../../../stats.css";
import StatComponent from "../StatComponent";

function StatsThisLastMonth() {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getStats(context, "This & Last Month")
        .then(function (result) {
          setData(result);
        })
        .finally(() => (context.isLoading = false));
    }
  });

  const getMonthNames = () => {
    const date = new Date();

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get current month name
    const currentMonth = monthNames[date.getMonth()];

    // Get last month name
    const lastMonth = monthNames[(date.getMonth() - 1 + 12) % 12]; // Handle January case

    return { currentMonth, lastMonth };
  };

  return (
    <StatComponent
      stats={data}
      title={getMonthNames().lastMonth + " & " + getMonthNames().currentMonth}
    />
  );
}

export default StatsThisLastMonth;
