import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import { sfQuery } from "../../API";
import { useParams } from "react-router";

const CashFlowDashboard = () => {
  const [data, setData] = useState(null);
  const [cancellations, setCancellations] = useState({});
  const [refunds, setRefunds] = useState({});
  const [credits, setCredits] = useState({});
  const [error, setError] = useState(false);
  const [newClientIds, setNewClientIds] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState("");
  const [lastMonthActualCollection, setLastMonthActualCollection] = useState(0);
  const [currentMonthActualCollection, setCurrentMonthActualCollection] =
    useState(0);
  const context = useContext(UserContext);

  let { owId, sidebar } = useParams();

  // Utility Functions
  const formatCurrency = (value) => {
    return value !== null && value !== undefined
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value)
      : "";
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const getMonth = (dt) => {
    return dt.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
  };

  const sanitizeDateString = (dateStr) => {
    if (typeof dateStr !== "string") return dateStr;
    const sanitized = dateStr.replace(/[^\d\/\-]/g, "");
    if (sanitized !== dateStr) {
      console.warn(`Sanitized date string from "${dateStr}" to "${sanitized}"`);
    }
    return sanitized;
  };

  const parseLocalDate = (dateStr) => {
    if (typeof dateStr !== "string") return new Date(dateStr);

    const sanitized = sanitizeDateString(dateStr);
    const [year, month, day] = sanitized.split("-").map(Number);

    if (
      isNaN(year) ||
      isNaN(month) ||
      isNaN(day) ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31
    ) {
      console.warn(`Invalid date string: "${dateStr}"`);
      return null;
    }

    return new Date(year, month - 1, day);
  };

  // Data Processing Functions
  const processGMOrders = (gmorders, miorders) => {
    const miOrdersByAccount = miorders.reduce((acc, order) => {
      if (!acc[order.AccountId]) acc[order.AccountId] = [];
      acc[order.AccountId].push(order);
      return acc;
    }, {});

    const groupedData = [];
    const newClientsSet = new Set();

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    gmorders.forEach((order) => {
      const relatedMIOrders = miOrdersByAccount[order.AccountId] || [];

      const activatedDate = parseLocalDate(order.Account.Activated_Date__c);

      const isActivatedThisMonth =
        activatedDate &&
        activatedDate.getMonth() === currentMonth &&
        activatedDate.getFullYear() === currentYear;

      if (isActivatedThisMonth) {
        newClientsSet.add(order.AccountId);
      }

      const lastMonthDate = new Date(currentDate);
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const lastMonth = getMonth(lastMonthDate);

      const thisMonth = getMonth(currentDate);

      groupedData.push({
        GMOrder: order,
        ThisMonthMIOrder:
          relatedMIOrders.find(
            (o) =>
              getMonth(parseLocalDate(o.Receive_Final_Payment__c)) === thisMonth
          ) || {},
        LastMonthMIOrder:
          relatedMIOrders.find(
            (o) =>
              getMonth(parseLocalDate(o.Receive_Final_Payment__c)) === lastMonth
          ) || {},
      });
    });

    setNewClientIds(newClientsSet);
    return groupedData;
  };

  const procesCancellations = (orderProds) => {
    const groupedData = {};

    orderProds.forEach((op) => {
      const cancelledDateStr =
        op.Order.Cancelled_Date__c || op.Cancelled_Date__c;
      const cancelledDate = parseLocalDate(cancelledDateStr);

      if (!cancelledDate) return;

      const month = getMonth(cancelledDate);

      if (!groupedData[month]) {
        groupedData[month] = { Cancellations: [] };
      }

      groupedData[month].Cancellations.push(op);
    });

    return groupedData;
  };

  const processTransactions = (transactions) => {
    const groupedData = {};

    transactions.forEach((op) => {
      const transactionDateStr = op.Transaction_Date__c;
      const transactionDate = parseLocalDate(transactionDateStr);

      if (!transactionDate) return;

      const month = getMonth(transactionDate);

      if (!groupedData[month]) {
        groupedData[month] = { Transactions: [] };
      }

      groupedData[month].Transactions.push(op);
    });

    return groupedData;
  };

  // Refresh Function to Fetch Data
  const refresh = async (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;

      try {
        const gmordersQuery = await sfQuery(
          "Order",
          "Id, AccountId, Account.Name, Account.Activated_Date__c, Payment_Date_new__c, Receive_Final_Payment__c, Next_Billing_Date__c, Total_Amount__c, Total_Discount_Calculated__c",
          `Order_Label__c LIKE 'GM-%' AND Status='Activated' AND (NOT Account.Name LIKE 'Test %') AND Account.RecordType.Name LIKE '%Customer%'`,
          context
        );

        const miordersQuery = await sfQuery(
          "Order",
          "Id, AccountId, Account.Name, Account.Activated_Date__c, Order_Label__c, Receive_Final_Payment__c, Next_Billing_Date__c, Total_Amount__c, Total_Discount_Calculated__c",
          `Order_Label__c LIKE 'MI-%' AND Payment_Date_new__c >= LAST_N_MONTHS:3 AND (NOT Account.Name LIKE 'Test %') AND Account.RecordType.Name LIKE '%Customer%' ORDER BY Payment_Date_new__c DESC`,
          context
        );

        const cancellationsQuery = await sfQuery(
          "OrderItem",
          "MRR__c, Cancelled_Date__c, Order.Cancelled_Date__c",
          `Order.Order_Label__c LIKE 'GM-%' 
          AND (NOT Product2.ProductCode IN ('HGGSPADSPEND', 'HGPPCADSPEND')) 
          AND (NOT Order.Account.Name LIKE '%test %') 
          AND ((Cancelled_Date__c >= LAST_N_MONTHS:6 
          AND Is_Cancelled__c = TRUE 
          AND Part_of_Cancelled_Order__c = FALSE 
          AND MRR_Exception__c = false) 
          OR (Order.Status IN ('Cancelled', 'Ala Carte Non Member') 
          AND Order.Cancelled_Date__c >= LAST_N_MONTHS:6 
          AND Part_of_Cancelled_Order__c = TRUE 
          AND Is_Cancelled__c = TRUE 
          AND MRR_Exception__c = false))`,
          context
        );

        const refundsQuery = await sfQuery(
          "Transaction__c",
          "Transaction_Amount__c, Transaction_Date__c",
          `RecordType.Name IN ('Partial Refund', 'Refund / Cancel Order') 
          AND Transaction_Date__c >= LAST_N_MONTHS:6`,
          context
        );

        const creditsQuery = await sfQuery(
          "Transaction__c",
          "Transaction_Amount__c",
          `RecordType.Name IN ('Credit') 
          AND Transaction_Date__c >= LAST_N_MONTHS:6`,
          context
        );

        setData(processGMOrders(gmordersQuery, miordersQuery));
        setCancellations(procesCancellations(cancellationsQuery));
        setRefunds(processTransactions(refundsQuery));
        setCredits(processTransactions(creditsQuery));
        setError(false);
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        context.isLoading = false;
      }
    }
  };

  // Calculate Actual Collections
  useEffect(() => {
    if (data && cancellations && refunds && credits) {
      // Determine Last Month
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const lastMonth = getMonth(lastMonthDate);

      // Calculate Last Month Actual Collection
      const lastMonthData = data.filter(
        (item) => !newClientIds.has(item.GMOrder.AccountId)
      );
      const lastMonthSearchFiltered = lastMonthData.filter((item) =>
        item.GMOrder.Account.Name.toLowerCase().includes(
          searchQuery.trim().toLowerCase()
        )
      );
      const lastMonthTotalPaid = lastMonthSearchFiltered
        .filter(
          (item) =>
            !item.LastMonthMIOrder.Receive_Final_Payment__c &&
            item.GMOrder.Total_Amount__c !== 0
        )
        .reduce(
          (total, item) => total + (item.GMOrder.Total_Amount__c || 0),
          0
        );

      const lastMonthRefundsTotal = refunds[lastMonth]?.Transactions
        ? refunds[lastMonth].Transactions.reduce(
            (total, txn) => total + (txn.Transaction_Amount__c || 0),
            0
          )
        : 0;

      const lastMonthCreditsTotal = credits[lastMonth]?.Transactions
        ? credits[lastMonth].Transactions.reduce(
            (total, txn) => total + (txn.Transaction_Amount__c || 0),
            0
          )
        : 0;

      const lastMonthCancellationsTotal = cancellations[lastMonth]
        ?.Cancellations
        ? cancellations[lastMonth].Cancellations.reduce(
            (total, cancel) => total + (cancel.MRR__c || 0),
            0
          )
        : 0;

      const lastMonthActual =
        lastMonthTotalPaid -
        lastMonthRefundsTotal -
        lastMonthCreditsTotal -
        lastMonthCancellationsTotal;
      setLastMonthActualCollection(lastMonthActual);

      // Determine Current Month
      const currentMonth = getMonth(new Date());

      // Calculate Current Month Actual Collection
      const currentMonthData = data;
      const currentMonthSearchFiltered = currentMonthData.filter((item) =>
        item.GMOrder.Account.Name.toLowerCase().includes(
          searchQuery.trim().toLowerCase()
        )
      );
      const currentMonthTotalPaid = currentMonthSearchFiltered
        .filter(
          (item) =>
            !item.ThisMonthMIOrder.Receive_Final_Payment__c &&
            item.GMOrder.Total_Amount__c !== 0
        )
        .reduce(
          (total, item) => total + (item.GMOrder.Total_Amount__c || 0),
          0
        );

      const currentMonthTotalPending = currentMonthSearchFiltered
        .filter(
          (item) =>
            item.GMOrder.Next_Billing_Date__c &&
            parseLocalDate(item.GMOrder.Next_Billing_Date__c) > new Date() &&
            item.GMOrder.Total_Amount__c !== 0 &&
            !item.ThisMonthMIOrder.Receive_Final_Payment__c
        )
        .reduce(
          (total, item) => total + (item.GMOrder.Total_Amount__c || 0),
          0
        );

      const currentMonthRefundsTotal = refunds[currentMonth]?.Transactions
        ? refunds[currentMonth].Transactions.reduce(
            (total, txn) => total + (txn.Transaction_Amount__c || 0),
            0
          )
        : 0;

      const currentMonthCreditsTotal = credits[currentMonth]?.Transactions
        ? credits[currentMonth].Transactions.reduce(
            (total, txn) => total + (txn.Transaction_Amount__c || 0),
            0
          )
        : 0;

      const currentMonthCancellationsTotal = cancellations[currentMonth]
        ?.Cancellations
        ? cancellations[currentMonth].Cancellations.reduce(
            (total, cancel) => total + (cancel.MRR__c || 0),
            0
          )
        : 0;

      const currentMonthActual =
        currentMonthTotalPaid +
        currentMonthTotalPending -
        currentMonthRefundsTotal -
        currentMonthCreditsTotal -
        currentMonthCancellationsTotal;
      setCurrentMonthActualCollection(currentMonthActual);
    }
  }, [data, cancellations, refunds, credits, newClientIds, searchQuery]);

  // Determine Color Based on Comparison
  const isCurrentHigher =
    currentMonthActualCollection > lastMonthActualCollection;
  // Using Unicode arrows for visual indicators
  const actualCollectionIndicator = isCurrentHigher ? "▲" : "▼";
  const actualCollectionColor = isCurrentHigher ? "#28a745" : "#dc3545"; // Green or Red

  // Notes Generator
  const getNotes = (row) => {
    return [
      row.IsOneOff && (
        <span
          key="one-off"
          style={{ fontSize: "0.8em", color: "#007bff", marginLeft: "5px" }}
        >
          One-Off{" "}
        </span>
      ),
      row.IsProrated && (
        <span
          key="prorated"
          style={{ fontSize: "0.8em", color: "#007bff", marginLeft: "5px" }}
        >
          Prorated (
          {new Date(
            row.Opportunity.Agreement_Start_Date__c
          ).toLocaleDateString()}
          )
        </span>
      ),
      row.IsOverdue && (
        <span
          key="overdue"
          style={{ fontSize: "0.8em", color: "#ff4d4d", marginLeft: "5px" }}
        >
          Overdue
        </span>
      ),
    ].filter(Boolean);
  };

  // Render Table Function
  const renderTable = (
    rows,
    isLastMonth,
    refundsData = { Transactions: [] },
    creditsData = { Transactions: [] },
    cancellationsData = { Cancellations: [] },
    actualCollectionColorOverride
  ) => {
    const isSearchActive = searchQuery.trim() !== "";

    const filteredRows = rows.filter((row) => {
      if (isLastMonth) {
        return !newClientIds.has(row.GMOrder.AccountId);
      }
      return true;
    });

    const searchFilteredRows = filteredRows.filter((row) =>
      row.GMOrder.Account.Name.toLowerCase().includes(
        searchQuery.trim().toLowerCase()
      )
    );

    const totalDue = isLastMonth
      ? searchFilteredRows
          .filter(
            (item) =>
              !item.LastMonthMIOrder.Receive_Final_Payment__c &&
              item.GMOrder.Total_Amount__c !== 0
          )
          .reduce(
            (total, item) => total + (item.GMOrder.Total_Amount__c || 0),
            0
          )
      : searchFilteredRows
          .filter(
            (item) =>
              !item.ThisMonthMIOrder.Receive_Final_Payment__c &&
              item.GMOrder.Total_Amount__c !== 0 &&
              (!item.GMOrder.Next_Billing_Date__c ||
                parseLocalDate(item.GMOrder.Next_Billing_Date__c) < new Date())
          )
          .reduce(
            (total, item) => total + (item.GMOrder.Total_Amount__c || 0),
            0
          );

    const totalPending = isLastMonth
      ? 0
      : searchFilteredRows
          .filter(
            (item) =>
              item.GMOrder.Next_Billing_Date__c &&
              parseLocalDate(item.GMOrder.Next_Billing_Date__c) > new Date() &&
              item.GMOrder.Total_Amount__c !== 0 &&
              !item.ThisMonthMIOrder.Receive_Final_Payment__c
          )
          .reduce(
            (total, item) => total + (item.GMOrder.Total_Amount__c || 0),
            0
          );

    const totalPaid = searchFilteredRows
      .filter(
        (item) =>
          (isLastMonth
            ? item.LastMonthMIOrder.Receive_Final_Payment__c
            : item.ThisMonthMIOrder.Receive_Final_Payment__c) ||
          item.GMOrder.Total_Amount__c === 0
      )
      .reduce((total, item) => total + (item.GMOrder.Total_Amount__c || 0), 0);

    const totalRefunds = refundsData.Transactions
      ? refundsData.Transactions.reduce(
          (total, txn) => total + (txn.Transaction_Amount__c || 0),
          0
        )
      : 0;

    const totalCredits = creditsData.Transactions
      ? creditsData.Transactions.reduce(
          (total, txn) => total + (txn.Transaction_Amount__c || 0),
          0
        )
      : 0;

    const totalCancellations = cancellationsData.Cancellations
      ? cancellationsData.Cancellations.reduce(
          (total, cancel) => total + (cancel.MRR__c || 0),
          0
        )
      : 0;

    const actualCollection = isLastMonth
      ? totalPaid - totalRefunds - totalCredits - totalCancellations
      : totalPaid +
        totalPending -
        totalRefunds -
        totalCredits -
        totalCancellations;

    return (
      <div style={{ marginBottom: "20px" }}>
        {/* Hide totals when search is active */}
        {!isSearchActive && (
          <>
            <h5>Collected: {formatCurrency(totalPaid)}</h5>
            {!isLastMonth && (
              <>
                <h5>Total Due: {formatCurrency(totalDue)}</h5>
                <h5>Total Pending: {formatCurrency(totalPending)}</h5>
              </>
            )}
            {isLastMonth && <h5>Total Due: {formatCurrency(totalDue)}</h5>}
            <h5 className="negative">
              Refunds: {formatCurrency(totalRefunds)}
            </h5>
            <h5 className="negative">
              Credits: {formatCurrency(totalCredits)}
            </h5>
            <h5 className="negative">
              Cancellations: {formatCurrency(totalCancellations)}
            </h5>
            <h4
              style={{
                display: "flex",
                alignItems: "center",
                color: actualCollectionColorOverride || "#000000",
              }}
            >
              Actual Collection: {formatCurrency(actualCollection)}
              {!isLastMonth && (
                <span style={{ marginLeft: "8px" }}>
                  {isCurrentHigher ? "▲" : "▼"}
                </span>
              )}
            </h4>
          </>
        )}
        <table>
          <thead>
            <tr>
              <th style={{ padding: "4px", fontSize: "11px" }}>Account</th>
              <th style={{ padding: "4px", fontSize: "11px" }}>Paid</th>
              <th style={{ padding: "4px", fontSize: "11px" }}>Amount</th>
              <th style={{ padding: "4px", fontSize: "11px" }}>Notes</th>
            </tr>
          </thead>
          <tbody>
            {searchFilteredRows
              .sort((a, b) => {
                if (!isLastMonth) {
                  const nextBillingDateA = parseLocalDate(
                    a.GMOrder.Next_Billing_Date__c
                  );
                  const isScheduledA =
                    nextBillingDateA &&
                    nextBillingDateA > new Date() &&
                    a.GMOrder.Total_Amount__c !== 0 &&
                    !a.ThisMonthMIOrder.Receive_Final_Payment__c;

                  const nextBillingDateB = parseLocalDate(
                    b.GMOrder.Next_Billing_Date__c
                  );
                  const isScheduledB =
                    nextBillingDateB &&
                    nextBillingDateB > new Date() &&
                    b.GMOrder.Total_Amount__c !== 0 &&
                    !b.ThisMonthMIOrder.Receive_Final_Payment__c;

                  if (isScheduledA && !isScheduledB) return -1;
                  if (!isScheduledA && isScheduledB) return 1;
                  if (isScheduledA && isScheduledB) {
                    return nextBillingDateA - nextBillingDateB;
                  }
                }

                if (isLastMonth) {
                  const isOverdueA =
                    !a.LastMonthMIOrder.Receive_Final_Payment__c &&
                    a.GMOrder.Total_Amount__c !== 0;
                  const isOverdueB =
                    !b.LastMonthMIOrder.Receive_Final_Payment__c &&
                    b.GMOrder.Total_Amount__c !== 0;

                  if (isOverdueA && !isOverdueB) return -1;
                  if (!isOverdueA && isOverdueB) return 1;
                  if (isOverdueA && isOverdueB) {
                    const nextBillingDateA = parseLocalDate(
                      a.GMOrder.Next_Billing_Date__c
                    );
                    const nextBillingDateB = parseLocalDate(
                      b.GMOrder.Next_Billing_Date__c
                    );

                    if (nextBillingDateA && nextBillingDateB) {
                      return nextBillingDateA - nextBillingDateB;
                    } else if (nextBillingDateA) {
                      return -1;
                    } else if (nextBillingDateB) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }
                }

                let dateA, dateB;

                if (isLastMonth) {
                  dateA = parseLocalDate(
                    a.LastMonthMIOrder.Receive_Final_Payment__c
                  );
                  dateB = parseLocalDate(
                    b.LastMonthMIOrder.Receive_Final_Payment__c
                  );
                } else {
                  dateA = parseLocalDate(
                    a.ThisMonthMIOrder.Receive_Final_Payment__c
                  );
                  dateB = parseLocalDate(
                    b.ThisMonthMIOrder.Receive_Final_Payment__c
                  );
                }

                const isAPaid =
                  (isLastMonth
                    ? a.LastMonthMIOrder.Receive_Final_Payment__c
                    : a.ThisMonthMIOrder.Receive_Final_Payment__c) ||
                  a.GMOrder.Total_Amount__c === 0;
                const isBPaid =
                  (isLastMonth
                    ? b.LastMonthMIOrder.Receive_Final_Payment__c
                    : b.ThisMonthMIOrder.Receive_Final_Payment__c) ||
                  b.GMOrder.Total_Amount__c === 0;

                if (!isAPaid && isBPaid) return -1;
                if (isAPaid && !isBPaid) return 1;
                if (!isAPaid && !isBPaid) return 0;

                if (dateA && dateB) {
                  return dateB - dateA;
                } else if (dateA) {
                  return -1;
                } else if (dateB) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map((row) => {
                const accountId = row.GMOrder.AccountId;
                const isNewClient = newClientIds.has(accountId);

                const nextBillingDate = parseLocalDate(
                  row.GMOrder.Next_Billing_Date__c
                );
                const isScheduled =
                  !isLastMonth &&
                  nextBillingDate &&
                  nextBillingDate > new Date() &&
                  row.GMOrder.Total_Amount__c !== 0 &&
                  !row.ThisMonthMIOrder.Receive_Final_Payment__c;

                const isOverdue =
                  isLastMonth &&
                  !row.LastMonthMIOrder.Receive_Final_Payment__c &&
                  row.GMOrder.Total_Amount__c !== 0;

                const isPaid =
                  (isLastMonth
                    ? row.LastMonthMIOrder.Receive_Final_Payment__c
                    : row.ThisMonthMIOrder.Receive_Final_Payment__c) ||
                  row.GMOrder.Total_Amount__c === 0;

                return (
                  <tr
                    key={row.GMOrder.Id}
                    style={{
                      backgroundColor: isScheduled
                        ? "#ffffff"
                        : isOverdue
                        ? "#f9e6e6"
                        : isPaid
                        ? "#e6f9e6"
                        : "#f9e6e6",
                    }}
                  >
                    <td
                      style={{
                        padding: "4px",
                        fontSize: "11px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={row.GMOrder.Account.Name}
                    >
                      {row.GMOrder.Account.Name ? (
                        <a
                          href={`https://gargleinc.lightning.force.com/${row.GMOrder.AccountId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncateText(row.GMOrder.Account.Name, 60)}
                        </a>
                      ) : (
                        ""
                      )}
                      {!isLastMonth && isNewClient && (
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "#28a745",
                            marginLeft: "5px",
                          }}
                        >
                          New
                        </span>
                      )}
                      {isScheduled && (
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "#007bff",
                            marginLeft: "5px",
                          }}
                        >
                          Scheduled on{" "}
                          {nextBillingDate
                            ? nextBillingDate.toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                            : ""}
                        </span>
                      )}
                      {isOverdue && (
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "#ff4d4d",
                            marginLeft: "5px",
                          }}
                        >
                          Overdue
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: "11px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={
                        (
                          isLastMonth
                            ? row.LastMonthMIOrder.Receive_Final_Payment__c
                            : row.ThisMonthMIOrder.Receive_Final_Payment__c
                        )
                          ? parseLocalDate(
                              isLastMonth
                                ? row.LastMonthMIOrder.Receive_Final_Payment__c
                                : row.ThisMonthMIOrder.Receive_Final_Payment__c
                            ).toLocaleDateString()
                          : ""
                      }
                    >
                      {isLastMonth
                        ? row.LastMonthMIOrder.Receive_Final_Payment__c
                          ? parseLocalDate(
                              row.LastMonthMIOrder.Receive_Final_Payment__c
                            ).toLocaleDateString()
                          : ""
                        : row.ThisMonthMIOrder.Receive_Final_Payment__c
                        ? parseLocalDate(
                            row.ThisMonthMIOrder.Receive_Final_Payment__c
                          ).toLocaleDateString()
                        : ""}
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: "11px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={
                        row.GMOrder.Total_Amount__c
                          ? formatCurrency(row.GMOrder.Total_Amount__c)
                          : formatCurrency(0)
                      }
                    >
                      {formatCurrency(
                        row.GMOrder.Total_Amount__c !== 0
                          ? row.GMOrder.Total_Amount__c
                          : 0
                      )}
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: "11px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={getNotes(row)}
                    >
                      {getNotes(row)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  // Fetch Data on Component Mount or Data Change
  useEffect(() => {
    refresh(!data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (context.accessToken === null) return <></>;
  if (error) return <ErrorMessage />;
  if (!data) return <Loader />;

  // Determine Last and Current Month Labels
  const currentDt = new Date();
  const currentMonthLabel = getMonth(currentDt);
  currentDt.setMonth(currentDt.getMonth() - 1);
  const lastMonthLabel = getMonth(currentDt);

  // Safeguard: Ensure data exists for both months
  const lastMonthCancellations = cancellations[lastMonthLabel] || {
    Cancellations: [],
  };
  const currentMonthCancellations = cancellations[currentMonthLabel] || {
    Cancellations: [],
  };

  const lastMonthRefunds = refunds[lastMonthLabel] || { Transactions: [] };
  const currentMonthRefunds = refunds[currentMonthLabel] || {
    Transactions: [],
  };

  const lastMonthCredits = credits[lastMonthLabel] || { Transactions: [] };
  const currentMonthCredits = credits[currentMonthLabel] || {
    Transactions: [],
  };

  return (
    <>
      {!error && (
        <>
          {!sidebar && <Sidebar />}
          <div className={owId ? "contentWOS dashboard" : "content dashboard"}>
            <div style={{ height: "100%", minHeight: "100%" }}>
              <div className="chart-container">
                <h1>Profitability Dashboard</h1>
                <input
                  type="text"
                  placeholder="Search by account name..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    padding: "8px",
                    marginBottom: "20px",
                    width: "100%",
                    boxSizing: "border-box",
                    fontSize: "14px",
                  }}
                />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* Last Month Section */}
                  <div style={{ width: "48%" }}>
                    <h2>{lastMonthLabel}</h2>
                    <hr />
                    {renderTable(
                      data,
                      true,
                      lastMonthRefunds,
                      lastMonthCredits,
                      lastMonthCancellations,
                      undefined // No color override for last month
                    )}
                  </div>

                  {/* Current Month Section */}
                  <div style={{ width: "48%" }}>
                    <h2>{currentMonthLabel}</h2>
                    <hr />
                    {renderTable(
                      data,
                      false,
                      currentMonthRefunds,
                      currentMonthCredits,
                      currentMonthCancellations,
                      actualCollectionColor // Color based on comparison
                    )}
                  </div>
                </div>

                {/* Display Actual Collections Comparison */}
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h3>Actual Collections Comparison: </h3>
                  <span
                    style={{
                      marginLeft: "10px",
                      color: actualCollectionColor,
                      fontSize: "1.5em",
                    }}
                  >
                    {actualCollectionIndicator}
                  </span>
                  <span
                    style={{ marginLeft: "5px", color: actualCollectionColor }}
                  >
                    {isCurrentHigher
                      ? "This month is higher"
                      : "This month is lower or equal"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <style jsx>{`
        .content {
          padding: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 10px 0;
        }
        th,
        td {
          border: 1px solid #ddd;
          text-align: left;
          padding: 4px;
          font-size: 11px;
        }
        th {
          background-color: #f4f4f4;
          font-weight: bold;
        }
        .negative {
          color: #dc3545; /* Bootstrap's red color for negative values */
        }
        h5 {
          border-bottom: 1px dotted gray;
        }
        h5.negative {
          text-align: right;
          color: red !important;
        }
        h4 {
          text-align: right;
        }

        @media (max-width: 768px) {
          .last-month-data {
            display: none;
          }

          .notes-column {
            width: 20%;
          }

          .opportunity-name-column {
            flex: 1;
          }
        }

        @media (max-width: 768px) {
          .card-container {
            flex-direction: column;
            align-items: center;
          }

          .card {
            width: 90%;
          }

          table {
            font-size: 10px;
          }

          th,
          td {
            padding: 3px;
          }
        }

        @media (max-width: 480px) {
          .card {
            padding: 10px;
          }

          th,
          td {
            font-size: 9px;
            padding: 2px;
          }

          h1 {
            font-size: 20px;
          }

          h2 {
            font-size: 16px;
          }

          .content {
            padding: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default CashFlowDashboard;
