// Charts.js
import React from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  BarChart,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import Done from "../../../done.png"; // Adjust the path as needed
import DoneWhite from "../../../done-white.png"; // Adjust the path as needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip as ReactTooltip } from "react-tooltip";

/* Retrieve CSS variables with default fallbacks */
let rootStyles;
if (typeof document !== "undefined") {
  rootStyles = getComputedStyle(document.documentElement);
}

/* Helper function to get CSS variable or fallback */
const getColorProperty = (propertyName, fallbackColor) => {
  const colorValue =
    rootStyles && rootStyles.getPropertyValue(propertyName).trim();
  return colorValue || fallbackColor;
};

/* Color Definitions with Default Fallbacks */
export const greenColor = getColorProperty("--green-color", "#00C49F");
export const yellowColor = getColorProperty("--yellow-color", "#FFBB28");
export const redColor = getColorProperty("--red-color", "#FF8042");
export const orangeColor = getColorProperty("--orange-color", "#FF8042");
export const blueColor = getColorProperty("--blue-color", "#0088FE");

export const multicolor = Array.from({ length: 10 }, (_, i) =>
  getColorProperty(
    `--multicolor-${i}`,
    `#${Math.floor(Math.random() * 16777215).toString(16)}`
  )
);

export const multicolor2 = Array.from({ length: 10 }, (_, i) =>
  getColorProperty(
    `--multicolor2-${i}`,
    `#${Math.floor(Math.random() * 16777215).toString(16)}`
  )
);

export const greenBorderColor = getColorProperty(
  "--green-border-color",
  "#008000"
);
export const yellowBorderColor = getColorProperty(
  "--yellow-border-color",
  "#FFFF00"
);
export const redBorderColor = getColorProperty("--red-border-color", "#FF0000");
export const blueBorderColor = getColorProperty(
  "--blue-border-color",
  "#0000FF"
);
export const grayBorderColor = getColorProperty(
  "--gray-border-color",
  "#808080"
);

export const borderWidth = 0.5;

/* Utility Functions */

/**
 * Creates a tooltip icon with optional tooltip text.
 * @param {string} toolTip - The tooltip text.
 * @returns JSX Element
 */
const createToolTip = (toolTip) => {
  const id = Date.now().toString() + Math.floor(Math.random() * 100);
  const tip = toolTip ? (
    <span
      data-tip
      data-for={id}
      style={{ marginLeft: "5px", cursor: "pointer" }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      <ReactTooltip id={id} effect="solid">
        {toolTip}
      </ReactTooltip>
    </span>
  ) : (
    ""
  );
  return tip;
};

/**
 * Checks if all data points are green (sum equals the first dataset's value).
 * @param {object} data - The chart data.
 * @returns {boolean}
 */
const getAllGreen = (data) => {
  if (!data || !data.datasets || data.datasets.length === 0) return false;
  let total = 0;
  let green =
    data.datasets.length > 1
      ? data.datasets[0].data[0]
      : data.datasets[0].data[2];
  // Adjust based on dataset structure
  for (const ds of data.datasets) {
    for (const d of ds.data) {
      total += +d;
    }
  }
  return total > 0 ? green === total : false;
};

/**
 * Checks if all data points are zero.
 * @param {object} data - The chart data.
 * @returns {boolean}
 */
const getIsEmpty = (data) => {
  if (!data || !data.datasets) return false;
  let total = 0;
  for (const ds of data.datasets) {
    for (const d of ds.data) {
      total += +d;
    }
  }
  return total === 0;
};

/**
 * Formats numbers with K/M suffixes.
 * @param {number} num - The number to format.
 * @returns {string|number}
 */
const numberFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K";
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else if (num < 900) {
    return num;
  }
  return num;
};

/**
 * Formatter for regular numbers.
 * @param {number} interval - Interval for formatting.
 * @returns {function}
 */
export const formatNumber = (interval = 1) => {
  return function (value, index) {
    if (!value) return value;

    let val =
      typeof index === "object"
        ? new Intl.NumberFormat("en-US").format(value)
        : "";

    val =
      typeof index === "number" && index % interval === 0
        ? numberFormatter(value)
        : val;

    return val;
  };
};

/**
 * Formatter for dollar amounts.
 * @param {number} interval - Interval for formatting.
 * @returns {function}
 */
export const dollarSign = (interval = 1) => {
  return function (value, index) {
    let val =
      typeof index === "object"
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumSignificantDigits: 2,
          }).format(value)
        : "";

    val =
      typeof index === "number" && index % interval === 0
        ? "$" + numberFormatter(value)
        : val;

    return val;
  };
};

/**
 * Formatter for percentage values.
 * @param {number} interval - Interval for formatting.
 * @returns {function}
 */
export const percentSign = (interval = 1) => {
  return function (value, index) {
    return typeof index === "object" || index % interval === 0
      ? new Intl.NumberFormat("en-US", {
          maximumSignificantDigits: 2,
        }).format(value) + " %"
      : "";
  };
};

/* Chart Setup Functions */

/**
 * Sets up datasets with multicolor scheme.
 * @param {Array} labels - The labels for the datasets.
 * @returns {Array}
 */
export const StackedMulticolorSetup = (labels) => {
  const arr = [];
  for (let i = 0; i < labels.length; i++) {
    arr.push({
      label: labels[i],
      data: [],
      backgroundColor: multicolor[i] || "#8884d8", // Fallback color
      borderColor: "white",
      borderWidth: borderWidth,
    });
  }
  return arr;
};

/**
 * Sets up datasets with second multicolor scheme.
 * @param {Array} labels - The labels for the datasets.
 * @returns {Array}
 */
export const StackedMulticolor2Setup = (labels) => {
  const arr = [];
  for (let i = 0; i < labels.length; i++) {
    arr.push({
      label: labels[i],
      data: [],
      backgroundColor: multicolor2[i] || "#82ca9d", // Fallback color
      borderColor: "white",
      borderWidth: borderWidth,
    });
  }
  return arr;
};

/**
 * Sets up datasets with green, yellow, and red colors.
 * @param {Array} labels - The labels for the datasets.
 * @returns {Array}
 */
export const StackedGreenYellowRedSetup = (labels) => {
  return [
    {
      label: labels[2],
      data: [],
      backgroundColor: greenColor, // green
      borderColor: greenBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[1],
      data: [],
      backgroundColor: yellowColor, // yellow
      borderColor: yellowBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[0],
      data: [],
      backgroundColor: redColor, // red
      borderColor: redBorderColor,
      borderWidth: borderWidth,
    },
  ];
};

/**
 * Sets up datasets with green and red colors.
 * @param {Array} labels - The labels for the datasets.
 * @returns {Array}
 */
export const StackedGreenRedSetup = (labels) => {
  return [
    {
      label: labels[1],
      data: [],
      backgroundColor: greenColor, // green
      borderColor: greenBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[0],
      data: [],
      backgroundColor: redColor, // red
      borderColor: redBorderColor,
      borderWidth: borderWidth,
    },
  ];
};

/**
 * Sets up datasets with green, yellow, red, and gray colors.
 * @param {Array} labels - The labels for the datasets.
 * @returns {Array}
 */
export const StackedGreenYellowRedGraySetup = (labels) => {
  return [
    {
      label: labels[2],
      data: [],
      backgroundColor: greenColor, // green
      borderColor: greenBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[1],
      data: [],
      backgroundColor: yellowColor, // yellow
      borderColor: yellowBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[0],
      data: [],
      backgroundColor: redColor, // red
      borderColor: redBorderColor,
      borderWidth: borderWidth,
    },
    {
      label: labels[3],
      data: [],
      backgroundColor: grayBorderColor, // gray
      borderColor: grayBorderColor,
      borderWidth: borderWidth,
    },
  ];
};

/* DMStackedChart Component */

/**
 * DMStackedChart Component
 *
 * This component utilizes Recharts to render a stacked BarChart with gradient fills.
 */
export const DMStackedChart = ({
  inprogress,
  data,
  title,
  subtitle,
  showLegends = false,
  horizontal = false,
  currency = false,
  percent = false,
  darkFont = false,
  toolTip = null,
  height = 300,
  width = null,
  annotation = null, // Recharts doesn't support annotations out of the box
}) => {
  // Prepare data for Recharts
  // Assuming 'data' has 'labels' and 'datasets' similar to Chart.js
  // Transform it to Recharts' expected format
  const rechartsData = data.labels.map((label, index) => {
    const entry = { name: label };
    data.datasets.forEach((dataset) => {
      entry[dataset.label] = dataset.data[index];
    });
    return entry;
  });

  // Extract dataset keys and colors
  const keys = data.datasets.map((dataset) => dataset.label);
  const colorsUsed = data.datasets.map(
    (dataset, index) =>
      dataset.backgroundColor || multicolor[index] || "#8884d8"
  );

  // Define tick formatting
  const formatTick = (value) => {
    if (currency) {
      return dollarFormatter(value);
    } else if (percent) {
      return `${value}%`;
    }
    return formatNumberFormatter(value);
  };

  // Formatter functions
  const formatNumberFormatter = (num) => {
    return numberFormatter(num);
  };

  const dollarFormatter = (num) => {
    return `$${numberFormatter(num)}`;
  };

  // Custom tooltip content if needed
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#0b5493",
            padding: "10px",
            border: "1px solid #ccc",
            color: "#fff",
          }}
        >
          <p>
            <strong>{label}</strong>
          </p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: colorsUsed[index] }}>
              {entry.name}: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  // Define chart style
  const style = {};
  if (height) {
    style.height = height;
    style.maxHeight = height;
  }
  if (width) {
    style.width = width;
    style.minWidth = width;
  }

  // Create tooltip if provided
  const tip = createToolTip(toolTip);

  // Helper function to parse gradient strings into objects
  const parseGradientString = (gradientString) => {
    // Remove 'linear-gradient(' from start and ')' from end
    const gradientContent = gradientString
      .replace(/linear-gradient\(\s*/i, "")
      .replace(/\)\s*$/, "");
    // Split by commas, but not inside parentheses
    const parts = gradientContent.split(/,(?![^\(]*\))/);

    // First part is the angle
    const angle = parts.shift().trim();

    // Remaining parts are color stops
    const colorStops = parts.map((stop) => stop.trim());

    return { angle, colorStops };
  };

  return (
    <>
      {(title || subtitle) && (
        <div className="chartTitle">
          {title && (
            <h2>
              {title} {tip}{" "}
              {(getAllGreen(data) || getIsEmpty(data)) && (
                <img className="all-done" src={Done} alt="All done!" />
              )}
            </h2>
          )}
          {subtitle && (
            <h5>
              {subtitle}{" "}
              {!title && (getAllGreen(data) || getIsEmpty(data)) && (
                <img className="all-done" src={Done} alt="All done!" />
              )}
            </h5>
          )}
        </div>
      )}
      {data && (
        <div className="chartContainer" style={style}>
          {getAllGreen(data) && (
            <img
              className="all-done-white"
              src={DoneWhite}
              alt="All done!"
              style={{
                left: "calc(50% + 12px)",
                bottom: "40px",
                position: "absolute",
              }}
            />
          )}
          {getIsEmpty(data) && (
            <img
              className="all-done-white"
              src={Done}
              alt="All done!"
              style={{
                position: "absolute",
                top: "50%",
                left: "calc(50% + 30px)",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={rechartsData}
              layout={horizontal ? "vertical" : "horizontal"}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              {/* Define gradients */}
              <defs>
                {colorsUsed.map((color, index) => {
                  const gradientId = `gradient-${index}`;

                  // Check if color is a gradient string
                  if (color.startsWith("linear-gradient")) {
                    const gradient = parseGradientString(color);

                    // Convert angle to SVG coordinates (simplified)
                    let x1 = "0%";
                    let y1 = "0%";
                    let x2 = "0%";
                    let y2 = "100%"; // Default to vertical gradient

                    if (gradient.angle.includes("deg")) {
                      const angleValue = parseFloat(gradient.angle);
                      // Convert angle to coordinates (simplified for common angles)
                      if (angleValue === 0 || angleValue === 360) {
                        x1 = "0%";
                        y1 = "0%";
                        x2 = "100%";
                        y2 = "0%";
                      } else if (angleValue === 90) {
                        x1 = "0%";
                        y1 = "0%";
                        x2 = "0%";
                        y2 = "100%";
                      } else if (angleValue === 180) {
                        x1 = "100%";
                        y1 = "0%";
                        x2 = "0%";
                        y2 = "0%";
                      } else if (angleValue === 270) {
                        x1 = "0%";
                        y1 = "100%";
                        x2 = "0%";
                        y2 = "0%";
                      } else if (angleValue === 135) {
                        x1 = "0%";
                        y1 = "100%";
                        x2 = "100%";
                        y2 = "0%";
                      } else {
                        // For other angles, default to vertical gradient
                        x1 = "0%";
                        y1 = "0%";
                        x2 = "0%";
                        y2 = "100%";
                      }
                    }

                    return (
                      <linearGradient
                        key={gradientId}
                        id={gradientId}
                        x1={x1}
                        y1={y1}
                        x2={x2}
                        y2={y2}
                      >
                        {gradient.colorStops.map((stop, i) => (
                          <stop
                            key={`stop-${i}`}
                            offset={`${
                              (i / (gradient.colorStops.length - 1)) * 100
                            }%`}
                            stopColor={stop}
                          />
                        ))}
                      </linearGradient>
                    );
                  } else {
                    // For solid colors, define a simple gradient
                    return (
                      <linearGradient
                        key={gradientId}
                        id={gradientId}
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor={color} stopOpacity={1} />
                        <stop offset="100%" stopColor={color} stopOpacity={1} />
                      </linearGradient>
                    );
                  }
                })}
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type={horizontal ? "number" : "category"}
                tickFormatter={formatTick}
              />
              <YAxis type={horizontal ? "category" : "number"} dataKey="name" />
              {showLegends && <Legend />}
              <RechartsTooltip content={<CustomTooltip />} />
              {keys.map((key, index) => {
                // Retrieve the corresponding gradient ID

                return (
                  <Bar
                    key={key}
                    dataKey={key}
                    stackId="a"
                    fill={`url(#gradient-${index})`}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
          {inprogress && (
            <>
              <hr />
              <div className="chartTitle ">
                <h5
                  style={{
                    marginRight: "7px",
                    display: "inline",
                  }}
                >
                  Work In Progress:
                </h5>
                <a href={inprogress}>Link to ticket.</a>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

/**
 * Sets up a single dataset for Pie charts with an array of background colors.
 * @param {Array} labels - The labels for the pie slices.
 * @param {Array} colors - The colors (or gradient strings) for each slice.
 * @returns {object}
 */
export const PieChartSetup = (labels, colors) => {
  return {
    labels: labels,
    datasets: [
      {
        label: "Dataset",
        data: [],
        backgroundColor: colors, // Array of colors or gradient strings
        borderColor: "white",
        borderWidth: borderWidth,
      },
    ],
  };
};

/**
 * DMPieChart Component
 *
 * This component utilizes Recharts to render a PieChart or Doughnut chart with gradient fills.
 */
export const DMPieChart = ({
  inprogress,
  data,
  title,
  subtitle,
  height = 300,
  showLegends = false,
  currency = false,
  percent = false,
  darkFont = false,
  toolTip,
  isDoughnut = false,
  total = null,
  annotation = null, // Recharts doesn't support annotations out of the box
}) => {
  // Prepare data for Recharts
  // Expecting a single dataset with data and backgroundColor arrays
  const pieData = data.labels.map((label, index) => ({
    name: label,
    value: data.datasets[0].data[index],
    color:
      data.datasets[0].backgroundColor &&
      data.datasets[0].backgroundColor[index]
        ? data.datasets[0].backgroundColor[index]
        : multicolor[index % multicolor.length],
  }));

  // Formatter functions
  const formatNumberFormatter = (num) => {
    return numberFormatter(num);
  };

  const dollarFormatter = (num) => {
    return `$${numberFormatter(num)}`;
  };

  // Custom tooltip content if needed
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const entry = payload[0];
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#0b5493",
            padding: "10px",
            border: "1px solid #ccc",
            color: "#fff",
          }}
        >
          <p>
            <strong>{entry.name}</strong>
          </p>
          <p>
            {currency
              ? dollarFormatter(entry.value)
              : percent
              ? `${(entry.percent * 100).toFixed(0)}%`
              : formatNumberFormatter(entry.value)}
          </p>
        </div>
      );
    }
    return null;
  };

  // Define chart style
  const style = {
    height: height,
    maxHeight: height,
    width: "100%",
    minWidth: 300,
    position: "relative",
  };

  // Create tooltip if provided
  const tip = createToolTip(toolTip);

  // Helper function to parse gradient strings into objects
  const parseGradientString = (gradientString) => {
    // Remove 'linear-gradient(' from start and ')' from end
    const gradientContent = gradientString
      .replace(/linear-gradient\(\s*/i, "")
      .replace(/\)\s*$/, "");
    // Split by commas, but not inside parentheses
    const parts = gradientContent.split(/,(?![^\(]*\))/);

    // First part is the angle
    const angle = parts.shift().trim();

    // Remaining parts are color stops
    const colorStops = parts.map((stop) => stop.trim());

    return { angle, colorStops };
  };

  return (
    <>
      {(title || subtitle) && (
        <div className="chartTitle">
          {title && (
            <h2>
              {title} {tip}{" "}
              {(getAllGreen(data) || getIsEmpty(data)) && (
                <img className="all-done" src={Done} alt="All done!" />
              )}
            </h2>
          )}
          {subtitle && (
            <h5>
              {subtitle}{" "}
              {!title && (getAllGreen(data) || getIsEmpty(data)) && (
                <img className="all-done" src={Done} alt="All done!" />
              )}
            </h5>
          )}
        </div>
      )}
      {total !== null && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            fontSize: "1.5em",
            fontWeight: "bold",
            color: darkFont ? "#000" : "#fff",
          }}
        >
          {total}
        </div>
      )}
      {data && (
        <div className="chartContainer" style={style}>
          {getAllGreen(data) && (
            <img
              className="all-done-white"
              src={DoneWhite}
              alt="All done!"
              style={{
                left: "calc(50% - 12px)",
                bottom: "100px",
                position: "absolute",
              }}
            />
          )}
          {getIsEmpty(data) && (
            <img
              className="all-done-white"
              src={Done}
              alt="All done!"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              {/* Move <defs> outside of <Pie> */}
              <defs>
                {pieData.map((entry, index) => {
                  const gradientId = `pie-gradient-${index}`;

                  // Check if color is a gradient string
                  if (
                    entry &&
                    entry.color &&
                    entry.color.startsWith &&
                    entry.color.startsWith("linear-gradient")
                  ) {
                    const gradient = parseGradientString(entry.color);

                    // Convert angle to SVG coordinates (simplified)
                    let x1 = "0%";
                    let y1 = "0%";
                    let x2 = "0%";
                    let y2 = "100%"; // Default to vertical gradient

                    if (gradient.angle.includes("deg")) {
                      const angleValue = parseFloat(gradient.angle);
                      // Convert angle to coordinates (simplified for common angles)
                      if (angleValue === 0 || angleValue === 360) {
                        x1 = "0%";
                        y1 = "0%";
                        x2 = "100%";
                        y2 = "0%";
                      } else if (angleValue === 90) {
                        x1 = "0%";
                        y1 = "0%";
                        x2 = "0%";
                        y2 = "100%";
                      } else if (angleValue === 180) {
                        x1 = "100%";
                        y1 = "0%";
                        x2 = "0%";
                        y2 = "0%";
                      } else if (angleValue === 270) {
                        x1 = "0%";
                        y1 = "100%";
                        x2 = "0%";
                        y2 = "0%";
                      } else if (angleValue === 135) {
                        x1 = "0%";
                        y1 = "100%";
                        x2 = "100%";
                        y2 = "0%";
                      } else {
                        // For other angles, default to vertical gradient
                        x1 = "0%";
                        y1 = "0%";
                        x2 = "0%";
                        y2 = "100%";
                      }
                    }

                    return (
                      <linearGradient
                        key={gradientId}
                        id={gradientId}
                        x1={x1}
                        y1={y1}
                        x2={x2}
                        y2={y2}
                      >
                        {gradient.colorStops.map((stop, i) => (
                          <stop
                            key={`pie-stop-${i}`}
                            offset={`${
                              (i / (gradient.colorStops.length - 1)) * 100
                            }%`}
                            stopColor={stop}
                          />
                        ))}
                      </linearGradient>
                    );
                  } else {
                    // For solid colors, define a simple gradient
                    return (
                      <linearGradient
                        key={gradientId}
                        id={gradientId}
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop
                          offset="0%"
                          stopColor={
                            typeof entry.color === Array
                              ? entry.color[0]
                              : entry.color
                          }
                          stopOpacity={1}
                        />
                        <stop
                          offset="100%"
                          stopColor={
                            typeof entry.color === Array
                              ? entry.color[0]
                              : entry.color
                          }
                          stopOpacity={1}
                        />
                      </linearGradient>
                    );
                  }
                })}
              </defs>
              <RechartsTooltip content={<CustomTooltip />} />
              {showLegends && <Legend />}
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={isDoughnut ? 80 : 100}
                innerRadius={isDoughnut ? 60 : 0}
                labelLine={false}
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`url(#pie-gradient-${index})`}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          {inprogress && (
            <>
              <hr />
              <div className="chartTitle ">
                <h5
                  style={{
                    marginRight: "7px",
                    display: "inline",
                  }}
                >
                  Work In Progress:
                </h5>
                <a href={inprogress}>Link to ticket.</a>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
