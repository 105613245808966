import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openURLInNewTab } from "../../Utils";
import {
  faPaperclip,
  faSearch,
  faChartLine,
  faTv,
  faChartPie,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faUser, // for Authenticate
  // New icons
  faUserTie, // Directors, Director Revenue
  faUsers, // Coordinators, Teams
  faGlobe, // Website, Front End Revenue
  faDollarSign, // Revenue button
  faSync, // Sales Lifecycle
  faMoneyBillWave, // Cash Flow
  faDownload, // Export List
  faComments, // Social+
  faBullhorn, // PPC
  faShareAlt, // Social Media
  faClipboardList, // Account Audit
  faFileAlt, // Content
  faSignOutAlt, // Logout
  faCogs, // Back End Revenue
  faHandshake,
  faArrowDownShortWide, // Partnership/Leads
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../API/AuthContext";
import RightPanel from "../RightPanel";

const Sidebar = () => {
  const { loading, userInfo } = useAuthContext();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showRevenue, setShowRevenue] = useState(false);
  const [showViews, setShowViews] = useState(false);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);
  const toggleRevenueOptions = () => setShowRevenue(!showRevenue);
  const toggleViewsOptions = () => setShowViews(!showViews);

  if (loading) {
    return <div>Loading...</div>;
  }

  const is_employee =
    userInfo && (userInfo.userRole === "admin" || userInfo.userRole === "user");
  const is_employee_admin = userInfo && userInfo.userRole === "admin";
  const is_api = userInfo && userInfo.userRole === "api";

  const growthRoutes = [
    { path: "/salesFlowDashboard", label: "Sales Flow", icon: faChartLine },
    {
      path: "/salesLifecycleDashboard",
      label: "Sales Lifecycle",
      icon: faSync,
    },
    { path: "/cashFlow", label: "Cash Flow", icon: faMoneyBillWave },
  ];

  if (!userInfo) {
    return (
      <>
        <RightPanel />
        <div className="sidebar-container">
          <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
            <div className="menu-title menu-name">Hello!</div>
            <NavLink
              to="/login"
              title="Authenticate to view all available menu options"
              className={({ isActive }) => (isActive ? "is-active" : "")}
            >
              <FontAwesomeIcon icon={faUser} title="Authenticate" />
              Authenticate{" "}
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Info"
                style={{ marginLeft: "5px", fontSize: "10px", opacity: "0.5" }}
              />
            </NavLink>
          </div>
          <button
            className="toggle-button"
            onClick={toggleSidebar}
            title="Toggle Sidebar"
          >
            <FontAwesomeIcon
              icon={isCollapsed ? faChevronRight : faChevronLeft}
              title="Toggle Sidebar"
            />
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <RightPanel />
      <div className="sidebar-container">
        <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
          <div className="menu-title menu-name">
            Hello, {userInfo.userFullName}
          </div>

          {is_employee && (
            <>
              <div className="menu-title">Accountability</div>
              <NavLink
                to="/directorsDashboard"
                title="Directors"
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <FontAwesomeIcon icon={faUserTie} title="Directors" />
                Directors
              </NavLink>
              <NavLink
                to="/coordinatorsDashboard"
                title="Coordinators"
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <FontAwesomeIcon icon={faUsers} title="Coordinators" />
                Coordinators
              </NavLink>
              <NavLink
                to="/WebsiteDashboard"
                title="Website"
                className={({ isActive }) => (isActive ? "is-active" : "")}
              >
                <FontAwesomeIcon icon={faGlobe} title="Website" />
                Website
              </NavLink>
              <div className="menu-title">
                <button
                  className="sidebar-collapsible"
                  onClick={toggleRevenueOptions}
                  title="Revenue"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faArrowDownShortWide}
                    title="Revenue"
                  />
                  Revenue
                </button>
              </div>
              {showRevenue && (
                <div id="revoptions">
                  {[
                    {
                      url: "/salesstats",
                      label: "TV Sales Stats",
                      icon: faTv,
                    },
                    {
                      url: "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY000000583w2AA/view?queryScope=userFolders",
                      label: "Back End Revenue",
                      icon: faCogs,
                    },
                    {
                      url: "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY00000058462AA/view?queryScope=userFolders",
                      label: "AE Revenue",
                      icon: faPaperclip,
                    },
                  ].map(({ url, label, icon }) => (
                    <a
                      href="/"
                      key={label}
                      title={label}
                      onClick={(e) => {
                        openURLInNewTab(url);
                        e.preventDefault();
                      }}
                      className={({ isActive }) =>
                        isActive ? "is-active" : ""
                      }
                    >
                      <FontAwesomeIcon icon={icon} title={label} />
                      {label}
                    </a>
                  ))}
                </div>
              )}
            </>
          )}

          {is_employee_admin && (
            <>
              <div className="menu-title">Growth</div>
              {growthRoutes.map(({ path, label, icon }) => (
                <NavLink
                  key={path}
                  to={path}
                  title={label}
                  className={({ isActive }) => (isActive ? "is-active" : "")}
                >
                  <FontAwesomeIcon icon={icon} title={label} />
                  {label}
                </NavLink>
              ))}
              <div className="menu-title">Data Widget</div>
              {[
                {
                  path: "/recordsList",
                  label: "Export List",
                  icon: faDownload,
                },
                {
                  path: "/recordsOverview",
                  label: "Overview",
                  icon: faChartPie,
                },
              ].map(({ path, label, icon }) => (
                <NavLink
                  key={path}
                  to={path}
                  title={label}
                  className={({ isActive }) => (isActive ? "is-active" : "")}
                >
                  <FontAwesomeIcon icon={icon} title={label} />
                  {label}
                </NavLink>
              ))}
            </>
          )}

          {is_employee && (
            <>
              <div className="menu-title">
                <button
                  className="sidebar-collapsible"
                  onClick={toggleViewsOptions}
                  title="Revenue"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faArrowDownShortWide}
                    title="Revenue"
                  />
                  Views
                </button>
              </div>
              {showViews && (
                <div id="viewoptions">
                  {[
                    { path: "/teams", label: "Teams", icon: faUsers },
                    { path: "/socialplus", label: "Social+", icon: faComments },
                    { path: "/website", label: "Website", icon: faGlobe },
                    { path: "/ppc", label: "PPC", icon: faBullhorn },
                    { path: "/seo", label: "SEO", icon: faSearch },
                    {
                      path: "/socialmedia",
                      label: "Social Media",
                      icon: faShareAlt,
                    },
                    {
                      path: "/audit",
                      label: "Account Audit",
                      icon: faClipboardList,
                    },
                    { path: "/content", label: "Content", icon: faFileAlt },
                  ].map(({ path, label, icon }) => (
                    <NavLink
                      key={path}
                      to={path}
                      title={label}
                      className={({ isActive }) =>
                        isActive ? "is-active" : ""
                      }
                    >
                      <FontAwesomeIcon icon={icon} title={label} />
                      {label}
                    </NavLink>
                  ))}
                </div>
              )}
            </>
          )}

          {is_api && (
            <NavLink
              to="/login"
              title="Authenticate to view all available menu options"
              className={({ isActive }) => (isActive ? "is-active" : "")}
            >
              <FontAwesomeIcon icon={faUser} title="Authenticate" />
              Authenticate{" "}
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Info"
                style={{ marginLeft: "5px", fontSize: "10px", opacity: "0.5" }}
              />
            </NavLink>
          )}

          {!is_api && (
            <NavLink
              to="/logout"
              title="Logout"
              className={({ isActive }) => (isActive ? "is-active" : "")}
            >
              <FontAwesomeIcon icon={faSignOutAlt} title="Logout" />
              Logout
            </NavLink>
          )}
        </div>

        <button
          className="toggle-button"
          onClick={toggleSidebar}
          title="Toggle Sidebar"
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronLeft}
            title="Toggle Sidebar"
          />
        </button>
      </div>
    </>
  );
};

export default Sidebar;
