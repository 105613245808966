import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import { getDirectors } from "../../SF";
import {
  DMPieChart,
  DMStackedChart,
  greenColor,
  orangeColor,
  redColor,
  yellowColor,
} from "../../UI/DMCharts";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import AttentionImg from "../../UI/AttentionImg";

import OpenTasksTable from "../../UI/OpenTasksTable";

import {
  _completedDirectorAccReview,
  _summaryAndActionItemEmails,
  _lastVPContact,
  _noOpenActivities,
  _overdueActivities,
  _riskOfCancellation,
  _gmCancellationsThisMonth,
  _productCancellationsThisMonth,
  _overduePayments,
  _upsellsThisMonth,
  _referralsThisMonth,
  _discussedReferralOpp,
  _gargleReview,
  _reviewsThisMonth,
  _directorUpsellDisc,
  _groupTasks,
  _noShow,
  _expiringThisMonth,
  _productRiskOfCancellation,
  _expiringDiscounts,
} from "../../Utils/CSInit";

import { getStorageValue, openURLInNewTab } from "../../Utils";
import Filter from "../../UI/Filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const DirectorsDashboard = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  let { owId, sidebar } = useParams();

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsOwners = [];

  const completedDirectorAccReview = _completedDirectorAccReview();
  const summaryAndActionItemEmails = _summaryAndActionItemEmails();
  const lastVPContact = _lastVPContact();
  const noOpenActivities = _noOpenActivities();
  const overdueActivities = _overdueActivities();
  const riskOfCancellation = _riskOfCancellation();
  const gmCancellationsThisMonth = _gmCancellationsThisMonth();
  const productCancellationsThisMonth = _productCancellationsThisMonth();
  const overduePayments = _overduePayments();
  const upsellsThisMonth = _upsellsThisMonth();
  const referralsThisMonth = _referralsThisMonth();
  const discussedReferralOpp = _discussedReferralOpp();
  const gargleReview = _gargleReview();
  const reviewsThisMonth = _reviewsThisMonth();
  const directorUpsellDisc = _directorUpsellDisc();
  const noShow = _noShow();
  const expiringThisMonth = _expiringThisMonth();
  const productRiskOfCancellation = _productRiskOfCancellation();
  const expiringDiscounts = _expiringDiscounts();

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getDirectors(context)
        .then(function (result) {
          refreshData(result);
        })
        .finally(() => (context.isLoading = false));
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  try {
    if (getStorageValue("gargle_analytics_owner_id"))
      context.OwnerId = getStorageValue("gargle_analytics_owner_id");
  } catch (err) {
    context.OwnerId = null;
    window.localStorage.clear();
  }

  if (data) {
    completedDirectorAccReview.datasets[2].data.push(
      data.CompletedDirectorAccountReview.Red
    );
    completedDirectorAccReview.datasets[0].data.push(
      data.CompletedDirectorAccountReview.Green
    );
    completedDirectorAccReview.labels[0] =
      data.CompletedDirectorAccountReview.Red +
      data.CompletedDirectorAccountReview.Green;

    summaryAndActionItemEmails.datasets[2].data.push(
      data.SummaryAndActionItemEmails.Red
    );
    summaryAndActionItemEmails.datasets[1].data.push(
      data.SummaryAndActionItemEmails.Yellow
    );
    summaryAndActionItemEmails.datasets[0].data.push(
      data.SummaryAndActionItemEmails.Green
    );
    summaryAndActionItemEmails.labels[0] =
      data.SummaryAndActionItemEmails.Red +
      data.SummaryAndActionItemEmails.Yellow +
      data.SummaryAndActionItemEmails.Green;

    console.log(data);

    lastVPContact.datasets[0].data.push(data.LastVPContact.Green);
    lastVPContact.datasets[2].data.push(data.LastVPContact.Red);
    lastVPContact.datasets[3].data.push(data.LastVPContact.Gray);
    lastVPContact.labels[0] =
      data.LastVPContact.Red +
      data.LastVPContact.Green +
      data.LastVPContact.Gray;

    noOpenActivities.datasets[2].data.push(data.NoOpenActivities.Red);
    noOpenActivities.labels[0] = data.NoOpenActivities.Red;

    overdueActivities.datasets[2].data.push(data.OverdueActivities.Red);
    overdueActivities.labels[0] = data.OverdueActivities.Red;

    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Red);
    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Yellow);
    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Green);
    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Gray);
    riskOfCancellation.labels[0] =
      data.RiskOfCancellation.Red +
      data.RiskOfCancellation.Yellow +
      data.RiskOfCancellation.Green +
      data.RiskOfCancellation.Gray;

    expiringThisMonth.datasets[0].data.push(data.ExpiringDiscounts.Red);
    expiringThisMonth.datasets[0].data.push(data.ExpiringDiscounts.Yellow);
    expiringThisMonth.datasets[0].data.push(0);
    expiringThisMonth.labels[0] =
      data.ExpiringDiscounts.Red + data.ExpiringDiscounts.Yellow;

    gmCancellationsThisMonth.datasets[2].data.push(
      data.GmCancellationsThisMonth.Red
    );
    gmCancellationsThisMonth.datasets[0].data.push(
      data.GmCancellationsThisMonth.Green
    );
    gmCancellationsThisMonth.labels[0] =
      data.GmCancellationsThisMonth.Red + data.GmCancellationsThisMonth.Green;

    productCancellationsThisMonth.datasets[2].data.push(
      data.ProductCancellationsThisMonth.Red
    );
    productCancellationsThisMonth.datasets[0].data.push(
      data.ProductCancellationsThisMonth.Green
    );
    productCancellationsThisMonth.labels[0] =
      data.ProductCancellationsThisMonth.Red +
      data.ProductCancellationsThisMonth.Green;

    overduePayments.datasets[2].data.push(data.OverduePayments.Red);
    overduePayments.datasets[0].data.push(data.OverduePayments.Green);
    overduePayments.labels[0] =
      data.OverduePayments.Red + data.OverduePayments.Green;

    upsellsThisMonth.datasets[2].data.push(data.UpsellsThisMonth.Red);
    upsellsThisMonth.datasets[0].data.push(data.UpsellsThisMonth.Green);
    upsellsThisMonth.labels[0] =
      data.UpsellsThisMonth.Red + data.UpsellsThisMonth.Green;

    referralsThisMonth.datasets[2].data.push(data.ReferralsThisMonth.Red);
    referralsThisMonth.datasets[0].data.push(data.ReferralsThisMonth.Green);
    referralsThisMonth.labels[0] =
      data.ReferralsThisMonth.Red + data.ReferralsThisMonth.Green;

    discussedReferralOpp.datasets[2].data.push(data.DiscussedReferralOpp.Red);
    discussedReferralOpp.datasets[0].data.push(data.DiscussedReferralOpp.Green);
    discussedReferralOpp.labels[0] =
      data.DiscussedReferralOpp.Red + data.DiscussedReferralOpp.Green;

    gargleReview.datasets[0].data.push(data.GargleReview.Green);
    gargleReview.labels[0] = data.GargleReview.Green;

    reviewsThisMonth.datasets[2].data.push(data.ReviewsThisMonth.Red);
    reviewsThisMonth.datasets[0].data.push(data.ReviewsThisMonth.Green);
    reviewsThisMonth.labels[0] =
      data.ReviewsThisMonth.Red + data.ReviewsThisMonth.Green;

    directorUpsellDisc.datasets[0].data.push(data.DirectorUpsellDisc.Green);
    directorUpsellDisc.labels[0] = data.DirectorUpsellDisc.Green;

    expiringDiscounts.datasets[2].data.push(data.expiringDiscountsTwo.Red);
    expiringDiscounts.datasets[1].data.push(data.expiringDiscountsTwo.Yellow);
    expiringDiscounts.datasets[0].data.push(data.expiringDiscountsTwo.Green);

    productRiskOfCancellation.datasets[0].data.push(
      data.ProductRiskOfCancellation.Red
    );
    productRiskOfCancellation.datasets[0].data.push(
      data.ProductRiskOfCancellation.Yellow
    );
    productRiskOfCancellation.datasets[0].data.push(
      data.ProductRiskOfCancellation.Green
    );

    noShow.datasets[2].data.push(data.NoShow.Red);
    noShow.labels[0] = data.NoShow.Red;

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    userOptionsOwners.push({ value: "null", label: "All Users" });

    for (const u of data.Owners) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
    }

    if (context.OwnerId)
      context.Owner = userOptionsOwners.find(
        (item) => item.value === context.OwnerId
      );
  }

  if (owId && owId !== "0") {
    context.OwnerId = owId;
  }

  try {
    window["bodyScroll"]();
    if (sidebar) {
      window["startScrolling"]();
    }
  } catch (err) {
    // Do nothing
  }

  console.log(data);

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {sidebar ? <></> : <Sidebar />}
          <div className={owId ? "contentWOS dashboard" : "content dashboard"}>
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsOwners={userOptionsOwners}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div
                  style={{
                    height: "100%",
                    minHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "20% 20% 20% 18% 20%",
                    }}
                  >
                    <a
                      href="https://gargleinc.lightning.force.com/lightning/r/Report/00ORo000003fqQjMAI/view"
                      target="_blank"
                      className="btn btn-light btn-reports"
                      rel="noopener noreferrer"
                    >
                      BOB Alex
                    </a>
                    <a
                      href="https://gargleinc.lightning.force.com/lightning/r/Report/00ORo000003fpHlMAI/view"
                      target="_blank"
                      className="btn btn-light btn-reports"
                      rel="noopener noreferrer"
                    >
                      BOB Chelsea
                    </a>
                    <div className="cs-title">
                      {!context.OwnerId ||
                      !context.Owner ||
                      context.Owner.value === "null"
                        ? "All Users"
                        : context.Owner.label}
                      <p className="cs-legend">
                        <span
                          className="square"
                          style={{ background: greenColor }}
                        ></span>
                        <span>Completed / On Time</span>
                        <span
                          className="square"
                          style={{ background: yellowColor }}
                        ></span>
                        <span>Needs Attention</span>
                        <span
                          className="square"
                          style={{ background: redColor }}
                        ></span>
                        <span>Urgent / Overdue</span>
                        <span
                          className="square"
                          style={{ background: orangeColor }}
                        ></span>
                        <span>Out of My Control</span>
                      </p>
                    </div>

                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000Jckp2AC/view"
                        );
                      }}
                    >
                      <span data-tip data-for={"ttActiveMembers"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"ttActiveMembers"}>
                          {"Membership Orders with Status = Activated"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "50px",
                          color: "white",
                        }}
                      >
                        Active Memberships
                      </h2>
                      <h1
                        style={{
                          color: "white",
                        }}
                      >
                        {data.TotalGM.CountAcc}
                      </h1>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "100%",
                      }}
                    >
                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background:
                            data.GmCancellationsThisMonth.Red > 0
                              ? redColor
                              : greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/sObject/00OEY000000Jj2j2AC/view"
                          );
                        }}
                      >
                        <span data-tip data-for={"ttCancelledGms"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"ttCancelledGms"}>
                            {
                              "Membership Orders moved to Ala Carte or Cancelled this month"
                            }
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "5px",
                            color: "white",
                          }}
                        >
                          Cancelled Memberships
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.GmCancellationsThisMonth.Red > 0
                            ? data.GmCancellationsThisMonth.Red
                            : data.GmCancellationsThisMonth.Green}
                        </h1>
                      </div>

                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background:
                            data.ProductCancellationsThisMonth.Red > 0
                              ? redColor
                              : greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/sObject/00O8W0000037AWWUA2/view"
                          );
                        }}
                      >
                        <span data-tip data-for={"ttCancelledProds"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"ttCancelledProds"}>
                            {
                              "Products Cancelled This Month, but Order still Activated"
                            }
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "5px",
                            color: "white",
                          }}
                        >
                          Cancelled Products
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.ProductCancellationsThisMonth.Red > 0
                            ? data.ProductCancellationsThisMonth.Red
                            : data.ProductCancellationsThisMonth.Green}
                        </h1>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "100%",
                      }}
                    >
                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background:
                            data.UpsellsThisMonth.Red +
                              data.UpsellsThisMonth.Green <
                            2
                              ? redColor
                              : greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "2px",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000LzaJ2AS/view?queryScope=userFolders"
                          );
                        }}
                      >
                        <span data-tip data-for={"ttUpsells"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"ttUpsells"}>
                            {"Upsell Opportunities with Director as setter."}
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "10px",
                            color: "white",
                          }}
                        >
                          Upsells
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.UpsellsThisMonth.Red > 0
                            ? data.UpsellsThisMonth.Red
                            : data.UpsellsThisMonth.Green}
                        </h1>
                      </div>
                      <div
                        className="chart-col chart-col-33 stat-card"
                        style={{
                          cursor: "pointer",
                          background:
                            data.ReferralsThisMonth.Red +
                              data.ReferralsThisMonth.Green <
                            2
                              ? redColor
                              : greenColor,
                          color: "white",
                          textAlign: "center",
                          height: "190px",
                          paddingBottom: "0",
                        }}
                        onClick={(e) => {
                          openURLInNewTab(
                            "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000MCkR2AW/view?queryScope=userFolders"
                          );
                        }}
                      >
                        <span data-tip data-for={"ttReferrals"}>
                          <FontAwesomeIcon
                            style={{ fontSize: "25px" }}
                            icon={faInfoCircle}
                          />
                          <ReactTooltip id={"ttReferrals"}>
                            {
                              "Leads with Lead Source Detail = Director Referral"
                            }
                          </ReactTooltip>
                        </span>
                        <h2
                          style={{
                            marginTop: "10px",
                            color: "white",
                          }}
                        >
                          Referrals
                        </h2>
                        <h1
                          style={{
                            color: "white",
                          }}
                        >
                          {data.ReferralsThisMonth.Red !== 0
                            ? data.ReferralsThisMonth.Red
                            : data.ReferralsThisMonth.Green}
                        </h1>
                      </div>
                    </div>

                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.NoOpenActivities.Red > 0 ? redColor : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000Jcv42AC/view"
                        );
                      }}
                    >
                      <span data-tip data-for={"ttNoPlannedTasks"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"ttNoPlannedTasks"}>
                          {"Accounts with no Tasks with Status = Open"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "50px",
                          color: "white",
                        }}
                      >
                        Accounts with No Planned Task
                      </h2>
                      <h1
                        style={{
                          color: "white",
                        }}
                      >
                        {data.NoOpenActivities.Red > 0
                          ? data.NoOpenActivities.Red
                          : data.NoOpenActivities.Green}
                      </h1>
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{
                        cursor: "pointer",
                        paddingTop: "0",
                        paddingBottom: "0",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPT2A0/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Overdue Payments"
                        data={overduePayments}
                        darkFont={darkFont}
                        toolTip={"MI Orders with payment overdue"}
                        height="350px"
                      />
                    </div>
                  </div>

                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "21% 21% 21% 30%",
                    }}
                  >
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getCompletedDirAccRw&report=CompletedDirAccRw&fields=&fieldnames=&returnto=/directorsDashboard";
                      }}
                    >
                      <DMStackedChart
                        subtitle="Completed DAR"
                        data={completedDirectorAccReview}
                        darkFont={darkFont}
                        toolTip={"Confirm DAR is done every 120 days or less"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPs2AK/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="SAI Emails"
                        data={summaryAndActionItemEmails}
                        darkFont={darkFont}
                        toolTip={"SAI Sent within 1 business day"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPi2AK/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="No Shows"
                        data={noShow}
                        darkFont={darkFont}
                        toolTip={"No Shows for any type of call"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdQ72AK/view"
                        );
                      }}
                    >
                      <DMPieChart
                        subtitle="Risk Of Cancellation"
                        data={riskOfCancellation}
                        height="300px"
                        darkFont={darkFont}
                        toolTip={"Risk of cancellation breakdown"}
                      />
                    </div>
                  </div>

                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "18% 18% 18% 18% 25%",
                    }}
                  >
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdQ22AK/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Discussed Referral Opportunities"
                        data={discussedReferralOpp}
                        darkFont={darkFont}
                        toolTip={
                          "Any accounts with the “Discussed Referral Opportunities” field completed on the SF Account page"
                        }
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPd2AK/view"
                        );
                      }}
                    >
                      {gargleReview.datasets[0].data[0] > 0 && (
                        <DMStackedChart
                          subtitle="Asked Gargle Review"
                          data={gargleReview}
                          darkFont={darkFont}
                          toolTip={"Reviews asked this month"}
                        />
                      )}
                      {gargleReview.datasets[0].data[0] === 0 && (
                        <AttentionImg title="Asked Gargle Review" />
                      )}
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPY2A0/view"
                        );
                      }}
                    >
                      {data.ReviewsThisMonth.Red + data.ReviewsThisMonth.Green >
                        0 && (
                        <DMStackedChart
                          subtitle="Reviews This Month"
                          data={reviewsThisMonth}
                          darkFont={darkFont}
                          toolTip={"Reviews left this month"}
                        />
                      )}
                      {data.ReviewsThisMonth.Red +
                        data.ReviewsThisMonth.Green ===
                        0 && <AttentionImg title="Reviews This Month" />}
                    </div>
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPx2AK/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Director Up-Sell Discussion"
                        data={directorUpsellDisc}
                        darkFont={darkFont}
                        toolTip={"This Month"}
                      />
                    </div>
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JlIP2A0/view"
                        );
                      }}
                    >
                      <DMPieChart
                        subtitle="Product Risk Of Cancellation"
                        data={productRiskOfCancellation}
                        height="300px"
                        darkFont={darkFont}
                        toolTip={"Bundle and per product discounts"}
                      />
                    </div>
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DirectorsDashboard;
