import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "./API/AuthContext";

const allowedIPs = ["204.225.25.114"];

function IPCheck() {
  const location = useLocation();
  const navigate = useNavigate();
  const { sidebar } = useParams();
  const { loading, userInfo, backendToken } = useAuthContext();

  const [ipChecked, setIpChecked] = useState(false);
  const [isAllowedIP, setIsAllowedIP] = useState(false);

  useEffect(() => {
    if (ipChecked) return; // Only fetch IP once

    fetch("https://api64.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => {
        const userIP = data.ip;

        if (allowedIPs.includes(userIP)) {
          setIsAllowedIP(true);
        }
        setIpChecked(true); // Mark IP check done
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
        setIpChecked(true); // Still mark it done to avoid blocking
      });
  }, [ipChecked]);

  useEffect(() => {
    if (loading || !ipChecked || !backendToken) return; // Don't redirect until user data AND token are fully ready

    if (sidebar && isAllowedIP) return;

    const isApiUser = userInfo?.userRole === "api";
    const isLoggedOut = !userInfo;
    let needsRedirect = isLoggedOut || (isApiUser && !isAllowedIP);

    if (location.pathname !== "/login" && needsRedirect && !isAllowedIP) {
      window.location.href = "/login";
    }
  }, [
    loading,
    userInfo,
    location.pathname,
    isAllowedIP,
    ipChecked,
    navigate,
    sidebar,
    backendToken,
  ]);

  return null;
}

export default IPCheck;
