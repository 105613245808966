// src/App.js
import "./App.css";

import React, { createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import AuthProvider from "./components/API/AuthContext";

import Teams from "./components/VIEWS/Teams";
import SocialPlus from "./components/VIEWS/SocialPlus";
import Website from "./components/VIEWS/Website";
import PPC from "./components/VIEWS/PPC";
import AccountTable from "./components/UI/AccountTable";
import SEO from "./components/VIEWS/SEO";
import SocialMedia from "./components/VIEWS/SocialMedia";
import Audit from "./components/INTERNAL_DASHBOARDS/Audit";
import Content from "./components/VIEWS/Content";
import DirectorsDashboard from "./components/INTERNAL_DASHBOARDS/DirectorsDashboard";
import CoordinatorsDashboard from "./components/INTERNAL_DASHBOARDS/CoordinatorsDashboard";
import VPsDashboard from "./components/INTERNAL_DASHBOARDS/VPsDashboard";
import ProfitabilityDashboard from "./components/INTERNAL_DASHBOARDS/ProfitabilityDashboard";
import WebsiteDashboard from "./components/INTERNAL_DASHBOARDS/WebsiteDashboard";
import SalesStats from "./components/SalesStats";
import RecordsList from "./components/CLIENT_FACING/DataWidget/recordsList";
import RecordsOverview from "./components/CLIENT_FACING/DataWidget/recordsOverview";
import Stats from "./components/INTERNAL_DASHBOARDS/SalesStats/Stats";
import StatsLastMonth from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsLastMonth";
import StatsLastQuarter from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsLastQuarter";
import StatsThisQuarter from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsThisQuarter";
import StatsThisLastMonth from "./components/INTERNAL_DASHBOARDS/SalesStats/StatsThisLastMonth";
import CashFlowDashboard from "./components/INTERNAL_DASHBOARDS/CashFlowDashboard";
import SalesLifecycleDashboard from "./components/INTERNAL_DASHBOARDS/SalesLifecycleDashboard";
import Login from "./components/Login";
import Logout from "./components/Logout";
import IPCheck from "./components/IPCheck";

export const UserContext = createContext(null);

function App() {
  /////////////////////////////////////////////// USE THIS FLAG TO PUT EVERYTHING IN MAINTENANCE
  const inMaintenance = false;
  let { sidebar } = useParams(); // This is a unique parameter used by TV dashboards to avoid login, and it's only allowed for specific IPs

  if (sidebar) {
    setTimeout(function () {
      window.location.reload();
    }, 600000);
  }

  return (
    <>
      {inMaintenance && (
        <div
          style={{
            height: "100%",
            width: "100%",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          We are doing some maintenance. We will be back soon :)
        </div>
      )}
      {!inMaintenance && (
        <React.StrictMode>
          <UserContext.Provider
            value={{
              accessToken:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiJEZW50YWxNYXJrZXRpbmciLCJuYW1lIjoiU2hhd24gUm93Ym90aGFtIiwiaWF0IjoxNTE2MjM5MDIyfQ.oX7S7xeHT2nEZEdkmQTtzzDzzc29cPDnMMtmkThXhgY",
              isLoading: false,
            }}
          >
            <AuthProvider>
              <Router>
                <IPCheck />
                <Routes>
                  <Route path="/" element={<SalesStats />} exact />
                  <Route path="/teams" element={<Teams />} exact />
                  <Route path="/report" element={<AccountTable />} exact />
                  <Route path="/socialplus" element={<SocialPlus />} exact />
                  <Route path="/website" element={<Website />} exact />
                  <Route path="/ppc" element={<PPC />} exact />
                  <Route path="/seo" element={<SEO />} exact />
                  <Route path="/socialmedia" element={<SocialMedia />} exact />
                  <Route path="/audit" element={<Audit />} exact />
                  <Route path="/content" element={<Content />} exact />
                  <Route path="/salesstats" element={<SalesStats />} exact />
                  <Route path="/login" element={<Login />} exact />
                  <Route path="/logout" element={<Logout />} exact />

                  <Route
                    path="/directorsDashboard/:owId?/:sidebar?/:hidediv?"
                    element={<DirectorsDashboard />}
                  />
                  <Route
                    path="/vpsDashboard/:vpId?/:sidebar?/:hidediv?"
                    element={<VPsDashboard />}
                    exact
                  />
                  <Route
                    path="/coordinatorsDashboard/:csId?/:sidebar?/:hidediv?"
                    element={<CoordinatorsDashboard />}
                    exact
                  />
                  <Route
                    path="/salesFlowDashboard"
                    element={<ProfitabilityDashboard />}
                    exact
                  />
                  <Route
                    path="/salesLifecycleDashboard"
                    element={<SalesLifecycleDashboard />}
                    exact
                  />
                  <Route
                    path="/cashFlow"
                    element={<CashFlowDashboard />}
                    exact
                  />
                  <Route
                    path="/WebsiteDashboard"
                    element={<WebsiteDashboard />}
                    exact
                  />
                  <Route path="/recordsList" element={<RecordsList />} exact />
                  <Route
                    path="/recordsOverview"
                    element={<RecordsOverview />}
                    exact
                  />
                  <Route path="/salesThisMonth" element={<Stats />} />
                  <Route
                    path="/salesThisLastMonth"
                    element={<StatsThisLastMonth />}
                  />
                  <Route path="/salesLastMonth" element={<StatsLastMonth />} />
                  <Route
                    path="/salesLastQuarter"
                    element={<StatsLastQuarter />}
                  />
                  <Route
                    path="/salesThisQuarter"
                    element={<StatsThisQuarter />}
                  />
                </Routes>
              </Router>
            </AuthProvider>
          </UserContext.Provider>
        </React.StrictMode>
      )}
    </>
  );
}

export default App;
