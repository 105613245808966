import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import { Row } from "react-bootstrap";

import Sidebar from "../../UI/Sidebar";
import { getContent } from "../../SF";
import Loader from "../../UI/Loader";
import Filter from "../../UI/Filter";
import { getStorageValue } from "../../Utils";

const Content = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getContent(context)
        .then(function (result) {
          setData(result);
          console.log(result);
        })
        .finally(() => (context.isLoading = false));
    }
  }); // Initial page load

  try {
    if (getStorageValue("gargle_analytics_owner_id"))
      context.OwnerId = getStorageValue("gargle_analytics_owner_id");
  } catch (err) {
    context.OwnerId = null;
    window.localStorage.clear();
  }

  const columns = [
    "Account",
    "Website URL",
    "Content Record",
    "Assigned Content",
    "Keywords",
    "Content Type",
    "Number of Pages",
    "Due Date",
    "Assigned To",
  ];

  const userOptionsOwners = [];
  if (data) {
    userOptionsOwners.push({ value: "null", label: "All Users" });
    console.log("DATA " + data);

    for (const u of data.Owners) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
      console.log(userOptionsOwners);
    }

    setTimeout(() => {
      window["reset"]();

      window["buildDataTable"](
        [
          { width: "200px", targets: 1 },
          { width: "150px", targets: 2 },
          { width: "150px", targets: 3 },
          { width: "150px", targets: 4 },
          { width: "100px", targets: 5 },
          { width: "150px", targets: 6 },
          { width: "150px", targets: 7 },
        ],
        [0, "asc"]
      );
    }, 500);
  }

  if (context.OwnerId)
    context.Owner = userOptionsOwners.find(
      (item) => item.value === context.OwnerId
    );

  return (
    <>
      <Sidebar />
      {
        <div className="content">
          {!data && <Loader />}
          {data && (
            <>
              <Row>
                <Filter
                  context={context}
                  userOptionsOwners={userOptionsOwners}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div className="chart-container" style={{ minWidth: "1700px" }}>
                  <div className="cs-title">
                    {!context.OwnerId ||
                    !context.Owner ||
                    context.Owner.value === "null"
                      ? "All Directors"
                      : context.Owner.label}{" "}
                    - {data.Accounts.length} accounts
                  </div>
                  <table
                    id="main-table"
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.Accounts.map((a) => {
                        console.log(a);
                        return (
                          <tr key={"row-" + a.Name}>
                            <td>
                              {" "}
                              <a
                                href={
                                  "https://gargleinc.lightning.force.com/" +
                                  a.Id
                                }
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {a.Name}
                              </a>
                            </td>
                            <td>
                              <a href={a.WebsiteURL}>{a.WebsiteURL}</a>
                            </td>
                            <td>
                              {a.RelevantRecord && (
                                <>
                                  {" "}
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.RelevantRecord.Id
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    Open
                                  </a>
                                </>
                              )}
                            </td>
                            <td>
                              {a.AssignedContent && (
                                <a
                                  href={
                                    "https://gargleinc.lightning.force.com/" +
                                    a.AssignedContent.Id
                                  }
                                >
                                  {a.AssignedContent.Name}
                                </a>
                              )}
                            </td>
                            <td>{a.Keywords}</td>
                            <td>{a.ContentType}</td>
                            <td>{a.NumberOfPages}</td>
                            <td>{a.ContentDueDate}</td>
                            <td>{a.AssignedTo}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Row>
            </>
          )}
        </div>
      }
    </>
  );
};

export default Content;
