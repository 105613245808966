import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { getStats } from "../../../SF";
import "../../../../stats.css";
import QTStatComponent from "../QTStatComponent";

function StatsThisQuarter() {
  const [stats, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!stats) {
      getStats(context, "This Quarter")
        .then(function (result) {
          setData(result);
        })
        .finally(() => (context.isLoading = false));
    }
  });

  return (
    <QTStatComponent
      stats={stats}
      title={"This Quarter"}
      qtfegoal={45000}
      qtbegoal={26910}
      qtcancelgoal={12000}
    />
  );
}

export default StatsThisQuarter;
