import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import { getVPsDashboard } from "../../SF";
import {
  DMPieChart,
  DMStackedChart,
  greenColor,
  orangeColor,
  redColor,
  yellowColor,
} from "../../UI/DMCharts";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import OpenTasksTable from "../../UI/OpenTasksTable";

import {
  _upsellsThisMonthVP,
  _additionalOffice,
  _referralsThisMonthVP,
  _mrrCancelationDollarsThisMonth,
  _gmCancellationsThisMonthVP,
  _productCancellationsThisMonthVP,
  _completedSemiAnnualReviewCall,
  _noShowSemiAnnualReview,
  _summaryAndActionItemEmailsVP,
  _noOpenActivitiesVP,
  _overdueActivitiesVP,
  _riskOfCancellationVP,
  _groupTasks,
  _sarsScheduledThisWeek,
  _sarsScheduledThisMonth,
  _scheduleSARTasksThisWeek,
  _productRiskOfCancellationVP,
  _contractTerms,
} from "../../Utils/CSInit";

import { getStorageValue, openURLInNewTab } from "../../Utils";
import Filter from "../../UI/Filter";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";

const VPsDashboard = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  let { vpId, sidebar } = useParams();

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsVPs = [];

  const additionalOffice = _additionalOffice();
  const summaryAndActionItemEmails = _summaryAndActionItemEmailsVP();
  const mrrCancelationDollarsThisMonth = _mrrCancelationDollarsThisMonth();
  const noOpenActivities = _noOpenActivitiesVP();
  const overdueActivities = _overdueActivitiesVP();
  const riskOfCancellation = _riskOfCancellationVP();
  const productRiskOfCancellation = _productRiskOfCancellationVP();
  const gmCancellationsThisMonth = _gmCancellationsThisMonthVP();
  const productCancellationsThisMonth = _productCancellationsThisMonthVP();
  const completedSemiAnnualReviewCall = _completedSemiAnnualReviewCall();
  const upsellsThisMonth = _upsellsThisMonthVP();
  const referralsThisMonth = _referralsThisMonthVP();
  const noShowSemiAnnualReview = _noShowSemiAnnualReview();
  const sarsScheduledThisWeek = _sarsScheduledThisWeek();
  const sarsScheduledThisMonth = _sarsScheduledThisMonth();
  const scheduleSARTasksThisWeek = _scheduleSARTasksThisWeek();
  const contractTerms = _contractTerms();

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getVPsDashboard(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  try {
    if (getStorageValue("gargle_analytics_vp_id"))
      context.VPId = getStorageValue("gargle_analytics_vp_id");
  } catch (err) {
    context.VPId = null;
    window.localStorage.clear();
  }

  if (data) {
    console.log(data);

    additionalOffice.datasets[2].data.push(data.AdditionalOffice.Red);
    additionalOffice.datasets[0].data.push(data.AdditionalOffice.Green);

    summaryAndActionItemEmails.datasets[2].data.push(
      data.SummaryAndActionItenEmails.Red
    );
    summaryAndActionItemEmails.datasets[1].data.push(
      data.SummaryAndActionItenEmails.Yellow
    );
    summaryAndActionItemEmails.datasets[0].data.push(
      data.SummaryAndActionItenEmails.Green
    );

    mrrCancelationDollarsThisMonth.datasets[2].data.push(
      data.MRRCancelationDollarsThisMonth.Red
    );
    mrrCancelationDollarsThisMonth.datasets[0].data.push(
      data.MRRCancelationDollarsThisMonth.Green
    );

    noOpenActivities.datasets[2].data.push(data.NoOpenActivities.Red);

    overdueActivities.datasets[2].data.push(data.OverdueActivities.Red);

    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Red);
    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Yellow);
    riskOfCancellation.datasets[0].data.push(data.RiskOfCancellation.Green);

    productRiskOfCancellation.datasets[0].data.push(
      data.ProductRiskOfCancellation.Red
    );
    productRiskOfCancellation.datasets[0].data.push(
      data.ProductRiskOfCancellation.Yellow
    );
    productRiskOfCancellation.datasets[0].data.push(
      data.ProductRiskOfCancellation.Green
    );

    gmCancellationsThisMonth.datasets[2].data.push(
      data.GMCancellationsThisMonth.Red
    );
    gmCancellationsThisMonth.datasets[0].data.push(
      data.GMCancellationsThisMonth.Green
    );

    productCancellationsThisMonth.datasets[2].data.push(
      data.ProductCancellationsThisMonth.Red
    );
    productCancellationsThisMonth.datasets[0].data.push(
      data.ProductCancellationsThisMonth.Green
    );

    completedSemiAnnualReviewCall.datasets[2].data.push(
      data.CompletedSemiAnnualReviewCall.Red
    );
    completedSemiAnnualReviewCall.datasets[0].data.push(
      data.CompletedSemiAnnualReviewCall.Green
    );
    completedSemiAnnualReviewCall.datasets[3].data.push(
      data.CompletedSemiAnnualReviewCall.Gray
    );
    completedSemiAnnualReviewCall.datasets[3].backgroundColor = orangeColor;

    upsellsThisMonth.datasets[2].data.push(data.UpsellsThisMonth.Red);
    upsellsThisMonth.datasets[0].data.push(data.UpsellsThisMonth.Green);

    referralsThisMonth.datasets[2].data.push(data.ReferralsThisMonth.Red);
    referralsThisMonth.datasets[0].data.push(data.ReferralsThisMonth.Green);

    noShowSemiAnnualReview.datasets[2].data.push(
      data.NoShowSemiAnnualReview.Red
    );
    noShowSemiAnnualReview.datasets[0].data.push(
      data.NoShowSemiAnnualReview.Green
    );

    sarsScheduledThisWeek.datasets[2].data.push(data.SarsScheduledThisWeek.Red);

    sarsScheduledThisMonth.datasets[2].data.push(
      data.SarsScheduledThisMonth.Red
    );

    scheduleSARTasksThisWeek.datasets[2].data.push(
      data.ScheduleSARTasksThisWeek.Red
    );

    contractTerms.datasets[0].data.push(data.ContractTerms.Red);
    contractTerms.datasets[0].data.push(0);
    contractTerms.datasets[0].data.push(data.ContractTerms.Green);

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    userOptionsVPs.push({ value: "null", label: "All Users" });

    for (const u of data.VPs) {
      userOptionsVPs.push({ value: u.Id, label: u.Name });
    }

    if (context.VPId)
      context.VP = userOptionsVPs.find((item) => item.value === context.VPId);
  }

  if (vpId && vpId !== "0") {
    context.VPId = vpId;
  }

  try {
    window["bodyScroll"]();
  } catch (err) {
    // Do nothing
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {sidebar ? <></> : <Sidebar />}
          <div
            className={vpId ? "contentWOS dashboard" : "content dashboard"}
            style={{
              background: "#EAEAEA",
              padding: "20px",
              paddingLeft: "40px",
            }}
          >
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsVPs={userOptionsVPs}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div
                  style={{
                    height: "100%",
                    minHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "25% 25% 25% 24%",
                      marginTop: "20px",
                    }}
                  >
                    <div className="cs-title">
                      {!context.VPId ||
                      !context.VP ||
                      context.VP.value === "null"
                        ? "All Users"
                        : context.VP.label}
                      <p className="cs-legend">
                        <span
                          className="square"
                          style={{ background: greenColor }}
                        ></span>
                        <span>Completed / On Time</span>
                        <span
                          className="square"
                          style={{ background: yellowColor }}
                        ></span>
                        <span>Needs Attention</span>
                        <span
                          className="square"
                          style={{ background: redColor }}
                        ></span>
                        <span>Urgent / Overdue</span>
                        <span
                          className="square"
                          style={{ background: orangeColor }}
                        ></span>
                        <span>Out of My Control</span>
                      </p>
                    </div>

                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.UpsellAmount < 7800 ? redColor : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KF0g2AG/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <span data-tip data-for={"upsells"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"upsells"}>
                          {
                            "Opportunities Closed Won this Month with Record Type = Upsell"
                          }
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "10px",
                          color: "white",
                        }}
                      >
                        Up Sells
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        #{" "}
                        {data.UpsellsThisMonth.Red +
                          data.UpsellsThisMonth.Green}{" "}
                        |{" "}
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        }).format(data.UpsellAmount)}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.VPReferrals <= 2 ? redColor : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9IF2A0/view"
                        );
                      }}
                    >
                      <span data-tip data-for={"referrals"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"referrals"}>
                          {"Leads created this month with Sales Setter = VP"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        # Referrals Received / # Converted
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        # {data.VPReferrals} / # {data.VPReferralsConverted}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.Accelerator.Green === 0 ? redColor : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9Mg2AK/view"
                        );
                      }}
                    >
                      <span data-tip data-for={"accelerator"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"accelerator"}>
                          {"Accelerator and Add-On Upsells Last 90 Days by VP"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        Accelerator / 1X
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        # {data.Accelerator.Green} |{" "}
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        }).format(data.Accelerator.Red)}{" "}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        color: "white",
                        textAlign: "center",
                        background:
                          data.CompletedSemiAnnualReviewCall.Red > 0
                            ? redColor
                            : greenColor,
                        verticalAlign: "middle",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000LcJL2A0/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <span data-tip data-for={"semianuual"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"semianuual"}>
                          {
                            "Completed SAR Calls within 6 months - * Tasks for same accounts are only counted once"
                          }
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "0px",
                          color: "white",
                        }}
                      >
                        Completed SAR Calls (On Time / Behind Schedule)
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        #{data.OnTimeSARs} / #{data.OffTimeSARs}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.OutstandingSemiAnnualReviewCalls.Red > 0
                            ? redColor
                            : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M3aN2AS/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <span data-tip data-for={"outstanding"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"outstanding"}>
                          {"Accounts that haven’t had an SAR call in 6 months"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        Outstanding SAR Calls
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        {data.OutstandingSemiAnnualReviewCalls.Red +
                          data.OutstandingSemiAnnualReviewCalls.Green}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.AnnualContract.Green < 15
                            ? redColor
                            : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9TD2A0/view"
                        );
                      }}
                    >
                      <span data-tip data-for={"annual"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"annual"}>
                          {"All Sales marked with Annual Agreement MTD"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        Annual Agreement Sales MTD
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        {data.AnnualContract.Green}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.CancelledGMsAmount +
                            data.CancelledProductsAmount >=
                          4000
                            ? redColor
                            : greenColor,
                        color: "white",
                        textAlign: "center",
                        gridColumn: "span 2",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Folder/00lEY000000MIDYYA4/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "50% 50%",
                          paddingBottom: "30px",
                          marginBottom: "10px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <div>
                          <h2
                            style={{
                              marginTop: "5px",
                              color: "white",
                            }}
                          >
                            Cancelled Products
                          </h2>
                          <h1
                            style={{
                              color: "white",
                              marginBottom: "-15px",
                            }}
                          >
                            #{" "}
                            {data.ProductCancellationsThisMonth.Red +
                              data.ProductCancellationsThisMonth.Green}{" "}
                            |{" "}
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "USD",
                            }).format(data.CancelledProductsAmount)}
                          </h1>
                        </div>
                        <div>
                          <h2
                            style={{
                              marginTop: "5px",
                              color: "white",
                            }}
                          >
                            Full Cancellations
                          </h2>
                          <h1
                            style={{
                              color: "white",
                              marginBottom: "-25px",
                            }}
                          >
                            #{" "}
                            {data.GMCancellationsThisMonth.Red +
                              data.GMCancellationsThisMonth.Green}{" "}
                            |{" "}
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "USD",
                            }).format(data.CancelledGMsAmount)}
                          </h1>
                        </div>
                      </div>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        }).format(
                          data.CancelledGMsAmount + data.CancelledProductsAmount
                        )}
                      </h1>
                    </div>
                  </div>
                </div>

                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "49% 49%",
                  }}
                >
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "50% 50%",
                      textAlign: "center",
                    }}
                  >
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9SU2A0/view?queryScope=userFolders"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>Total MRR - MTD</h2>
                        <h1>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(data.TotalMRRperClient)}
                        </h1>
                      </a>
                    </div>
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9SZ2A0/view?queryScope=userFolders"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>Average MRR - MTD</h2>
                        <h1>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(Math.round(data.AverageMRRperClient))}
                        </h1>
                      </a>
                    </div>
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KQI72AO/view"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2># VP Referral Opportunities Closed</h2>
                        <h1>{data.ReferralLeadsThisMonth}</h1>
                      </a>
                    </div>
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9Ey2AK/view"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>Completed Demo - Advisor Referral Last 60 Days</h2>
                        <h1>{data.CompletedReferralDemos}</h1>
                      </a>
                    </div>
                  </div>
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "50% 50%",
                      textAlign: "center",
                    }}
                  >
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000LB5c2AG/view?queryScope=userFolders"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>Advisor Referral Close Rate</h2>
                        <h1>{data.ReferralsCloseRate}%</h1>
                      </a>
                    </div>
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9UL2A0/view?queryScope=userFolders"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>SAR Close Rate</h2>
                        <h1>{data.SARCloseRate.Green}%</h1>
                      </a>
                    </div>
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M71v2AC/view?queryScope=userFolders"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>SAR Average Deal Size - MTD</h2>
                        <h1>
                          {" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(Math.round(data.SARAverageDealSize.Green))}
                        </h1>
                      </a>
                    </div>
                    <div className="gray-card">
                      <a
                        href="https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000LcJL2A0/view?queryScope=userFolders"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h2>SAR Tasks Scheduled (Completed / Pending)</h2>
                        <h1>
                          #{data.SarsScheduledThisMonth.Green} / #
                          {data.SarsScheduledThisMonth.Red}
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "33% 33% 33%",
                  }}
                >
                  <div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.NoOpenActivities.Red > 0 ? redColor : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9ZV2A0/view"
                        );
                      }}
                    >
                      <h2
                        style={{
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        No Open Activities
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        {data.NoOpenActivities.Red}
                      </h1>
                    </div>
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background:
                          data.OverdueActivities.Red > 0
                            ? redColor
                            : greenColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9aO2AS/view"
                        );
                      }}
                    >
                      <h2
                        style={{
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        Overdue Activities
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "5px",
                        }}
                      >
                        {data.OverdueActivities.Red}
                      </h1>
                    </div>
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdPi2AK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Show SAR"
                      data={noShowSemiAnnualReview}
                      darkFont={darkFont}
                      toolTip={"No Shows for any type of call"}
                    />
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KT2C2AW/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="SAI Emails"
                      data={summaryAndActionItemEmails}
                      darkFont={darkFont}
                      toolTip={"SAI Sent within 1 business day"}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdQ72AK/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="Risk Of Cancellation"
                      data={riskOfCancellation}
                      darkFont={darkFont}
                      toolTip={"Risk of cancellation breakdown"}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000JdQ72AK/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="Product Risk Of Cancellation"
                      data={productRiskOfCancellation}
                      darkFont={darkFont}
                      toolTip={"Product Risk of cancellation breakdown"}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000M9ad2AC/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="Clients Contract Terms"
                      data={contractTerms}
                      darkFont={darkFont}
                      toolTip={"Clients by Contract Terms"}
                    />
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VPsDashboard;
