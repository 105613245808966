import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";

const TypingEffect = ({ text, speed = 10 }) => {
  const [displayedText, setDisplayedText] = useState("");

  // Function to format the conversation
  const formatConversation = (inputText) => {
    return inputText
      .split(/(Caller:|Receiver:)/) // Splits text while keeping "Caller:" & "Receiver:"
      .filter((segment) => segment.trim() !== "") // Removes empty segments
      .map(
        (segment, index) =>
          segment === "Caller:" || segment === "Receiver:"
            ? `**${segment}** ` // Bold for speaker names
            : `${segment.trim()}\n\n` // Adds proper spacing between responses
      )
      .join(""); // Joins back into a single text block
  };

  useEffect(() => {
    let index = 0;
    const formattedText = formatConversation(text);
    const timer = setInterval(() => {
      index++;
      setDisplayedText(formattedText.slice(0, index));

      if (index === formattedText.length) {
        clearInterval(timer);
      }
    }, speed);

    return () => clearInterval(timer);
  }, [text, speed]);

  return (
    <div className="chat-message">
      <Markdown
        options={{
          overrides: {
            code: {
              component: ({ className, children, ...props }) => {
                const match = /language-(\w+)/.exec(className || "");
                return match ? (
                  <SyntaxHighlighter
                    style={github}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {String(children).replace(/\n$/, "")}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            },
          },
        }}
      >
        {displayedText}
      </Markdown>
    </div>
  );
};

export default TypingEffect;
