import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import { Row } from "react-bootstrap";

import Sidebar from "../../UI/Sidebar";
import { getSEO } from "../../SF";
import Loader from "../../UI/Loader";
import Filter from "../../UI/Filter";
import { getStorageValue } from "../../Utils";

const SEO = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getSEO(context)
        .then(function (result) {
          setData(result);
          console.log(result);
        })
        .finally(() => (context.isLoading = false));
    }
  }); // Initial page load

  try {
    if (getStorageValue("gargle_analytics_owner_id"))
      context.OwnerId = getStorageValue("gargle_analytics_owner_id");
  } catch (err) {
    context.OwnerId = null;
    window.localStorage.clear();
  }

  const columns = [
    "Account",
    "SEO Record",
    "Next Tasks",
    "Quarterly Task Position",
    "Burst SEO",
    "Google Analytics Setup",
    "Google Search Console Setup",
    "Google Tag Manager Setup",
    "Google My Business Setup",
  ];

  const userOptionsOwners = [];
  if (data) {
    userOptionsOwners.push({ value: "null", label: "All Users" });
    console.log(data);

    for (const u of data.Owners) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
      console.log(userOptionsOwners);
    }

    setTimeout(() => {
      window["reset"]();

      window["buildDataTable"](
        [
          { width: "100px", targets: 2 },
          { width: "100px", targets: 3 },
          { width: "100px", targets: 4 },
          { width: "100px", targets: 5 },
          { width: "150px", targets: 6 },
        ],
        [0, "asc"]
      );
    }, 500);
  }

  if (context.OwnerId)
    context.Owner = userOptionsOwners.find(
      (item) => item.value === context.OwnerId
    );

  return (
    <>
      <Sidebar />
      {
        <div className="content">
          {!data && <Loader />}
          {data && (
            <>
              <Row>
                <Filter
                  context={context}
                  userOptionsOwners={userOptionsOwners}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div className="chart-container" style={{ minWidth: "1800px" }}>
                  <div className="cs-title">
                    {!context.OwnerId ||
                    !context.Owner ||
                    context.Owner.value === "null"
                      ? "All Directors"
                      : context.Owner.label}{" "}
                    - {data.Accounts.length} accounts
                  </div>
                  <table
                    id="main-table"
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.Accounts.map((a) => {
                        const quaterlyStyleM1 = {
                          backgroundColor: "#BDE2D7",
                        };
                        const quaterlyStyleM2 = {
                          backgroundColor: "#F4DEC5",
                        };
                        const quaterlyStyleM3 = {
                          backgroundColor: "#F2CBB8",
                        };
                        const defaultStyle = {
                          backgroundColor: "white",
                        };
                        return (
                          <tr key={"row-" + a.Name}>
                            <td>
                              {" "}
                              <a
                                href={
                                  "https://gargleinc.lightning.force.com/" +
                                  a.Id
                                }
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {a.Name}
                              </a>
                            </td>
                            <td>
                              {a.RelevantRecord && (
                                <>
                                  {" "}
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      a.RelevantRecord.Id
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    Open
                                  </a>
                                </>
                              )}
                            </td>
                            <td>
                              {a.NextTask &&
                                a.NextTask.map((t) => {
                                  return (
                                    <p key={t.Id}>
                                      <a
                                        href={
                                          "https://gargleinc.lightning.force.com/" +
                                          t.Id
                                        }
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {t.Subject}
                                        <br />
                                        {t.ActivityDate}
                                      </a>
                                      <br />
                                      <br />
                                    </p>
                                  );
                                })}
                            </td>
                            <td
                              style={
                                a.QuarterlyTaskPosition[
                                  a.QuarterlyTaskPosition.length - 1
                                ] &&
                                a.QuarterlyTaskPosition[
                                  a.QuarterlyTaskPosition.length - 1
                                ].Task_Type__c.includes("M1")
                                  ? quaterlyStyleM1
                                  : a.QuarterlyTaskPosition[
                                      a.QuarterlyTaskPosition.length - 1
                                    ] &&
                                    a.QuarterlyTaskPosition[
                                      a.QuarterlyTaskPosition.length - 1
                                    ].Task_Type__c.includes("M2")
                                  ? quaterlyStyleM2
                                  : a.QuarterlyTaskPosition[
                                      a.QuarterlyTaskPosition.length - 1
                                    ] &&
                                    a.QuarterlyTaskPosition[
                                      a.QuarterlyTaskPosition.length - 1
                                    ].Task_Type__c.includes("M3")
                                  ? quaterlyStyleM3
                                  : defaultStyle
                              }
                            >
                              {a.QuarterlyTaskPosition[
                                a.QuarterlyTaskPosition.length - 1
                              ] && (
                                <>
                                  {
                                    a.QuarterlyTaskPosition[
                                      a.QuarterlyTaskPosition.length - 1
                                    ].Task_Type__c
                                  }
                                  <br />
                                  {
                                    a.QuarterlyTaskPosition[
                                      a.QuarterlyTaskPosition.length - 1
                                    ].ActivityDate
                                  }
                                </>
                              )}
                            </td>
                            <td>
                              {
                                <>
                                  <input
                                    type="checkbox"
                                    id={a.BurstSEO}
                                    value={a.BurstSEO}
                                    disabled
                                    checked={a.BurstSEO}
                                  />
                                  {"  "}
                                  <span>{a.BurstSEOEnd}</span>
                                </>
                              }
                            </td>
                            <td>
                              {
                                <input
                                  type="checkbox"
                                  id={a.GoogleAnalyticsSetup}
                                  value={a.GoogleAnalyticsSetup}
                                  disabled
                                  checked={a.GoogleAnalyticsSetup}
                                />
                              }
                            </td>
                            <td>
                              {
                                <input
                                  type="checkbox"
                                  id={a.GoogleSearchConsoleSetup}
                                  value={a.GoogleSearchConsoleSetup}
                                  disabled
                                  checked={a.GoogleSearchConsoleSetup}
                                />
                              }
                            </td>
                            <td>
                              {
                                <input
                                  type="checkbox"
                                  id={a.GoogleTagManagerSetup}
                                  value={a.GoogleTagManagerSetup}
                                  disabled
                                  checked={a.GoogleTagManagerSetup}
                                />
                              }
                            </td>
                            <td>
                              {
                                <input
                                  type="checkbox"
                                  id={a.GoogleMyBusinesSetup}
                                  value={a.GoogleMyBusinesSetup}
                                  disabled
                                  checked={a.GoogleMyBusinesSetup}
                                />
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Row>
            </>
          )}
        </div>
      }
    </>
  );
};

export default SEO;
