import React, { createContext, useState, useEffect, useContext } from "react";
import { authenticate, getUserInfo } from "./../API/index";
import axios from "axios";
import { logout } from "./../Utils/index";
import { auth } from "./firebaseConfig";
import Loader from "./../UI/Loader";
import { useParams } from "react-router";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  let { sidebar } = useParams(); // This is a unique parameter used by TV dashboards to avoid login, and it's only allowed for specific IPs

  const [authenticationToken, setAuthToken] = useState(
    localStorage.getItem("authToken")
  );
  const [backendToken, setBackendToken] = useState(
    localStorage.getItem("backendToken")
  );

  const [userInfo, setUserInfo] = useState(null);
  const [userId, setUserId] = useState(
    localStorage.getItem("userId") ||
      (sidebar ? process.env.REACT_APP_INTEGRATIONS_USR : null)
  );

  const [expiresIn, setExpiresIn] = useState(localStorage.getItem("expiresIn"));
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [backendLoading, setBackendLoading] = useState(true);

  const expired = !expiresIn || new Date().getTime() > expiresIn;

  /**
   * Step 1: Ensure Axios headers are always set from storage when navigating
   */
  useEffect(() => {
    const storedAuthToken = localStorage.getItem("authToken");
    const storedBackendToken = localStorage.getItem("backendToken");

    if (storedAuthToken) {
      axios.defaults.headers.common["x-user-authorization"] = storedAuthToken;
      setAuthToken(storedAuthToken);
    }
    if (storedBackendToken) {
      axios.defaults.headers.common["Authorization"] = storedBackendToken;
      setBackendToken(storedBackendToken);
    }
  }, []);

  /**
   * Step 2: Listen for Firebase Authentication State
   */
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(true);

          localStorage.setItem("authToken", token);
          localStorage.setItem("userId", currentUser.email);

          setAuthToken(token);
          setUserId(currentUser.email);
          axios.defaults.headers.common["x-user-authorization"] = token;

          const { backendToken } = await authenticate(currentUser.email);
          const expiration = new Date().getTime() + 60 * 60 * 1000;

          localStorage.setItem("backendToken", backendToken);
          localStorage.setItem("expiresIn", expiration);

          setBackendToken(backendToken);
          setExpiresIn(expiration);
          axios.defaults.headers.common["Authorization"] = backendToken;
        } catch (error) {
          console.error("Error fetching Firebase token:", error);
          logout();
        }
      } else {
        logout();
      }
      setFirebaseLoading(false);
    });

    return () => unsubscribe();
  }, []);

  /**
   * Step 3: Authenticate Backend Once Firebase is Ready
   * Fix: If backendToken is already stored and not expired, set backendLoading to false immediately.
   */
  useEffect(() => {
    const authenticateBackend = async () => {
      if (!userId || !authenticationToken) {
        setBackendLoading(false); // No Firebase auth, no backend auth needed
        return;
      }

      if (backendToken && !expired) {
        setBackendLoading(false); // Already authenticated, no need to re-authenticate
        return;
      }

      if (
        !sidebar &&
        (!userId || userId === process.env.REACT_APP_INTEGRATIONS_USR)
      ) {
        setBackendLoading(false); // Sidebar will try to login with API, otherwise will go to login
        return;
      }

      try {
        const { backendToken } = await authenticate(userId);
        const expiration = new Date().getTime() + 60 * 60 * 1000;

        localStorage.setItem("backendToken", backendToken);
        localStorage.setItem("expiresIn", expiration);

        setBackendToken(backendToken);
        setExpiresIn(expiration);
        axios.defaults.headers.common["Authorization"] = backendToken;
      } catch (err) {
        console.error("Backend authentication failed", err);
        logout();
      } finally {
        setBackendLoading(false);
      }
    };

    if (!firebaseLoading) {
      authenticateBackend();
    }
  }, [
    userId,
    authenticationToken,
    backendToken,
    expired,
    firebaseLoading,
    sidebar,
  ]);

  useEffect(() => {
    if (
      !sidebar &&
      (!userId || userId === process.env.REACT_APP_INTEGRATIONS_USR)
    ) {
      return;
    }

    if (userId && backendToken && backendToken !== "undefined") {
      getUserInfo().then((u) => {
        setUserInfo(u);
      });
    }
  }, [userId, backendToken, sidebar]);

  const loading = firebaseLoading || backendLoading;
  const is_login = window.location.href.indexOf("/login") !== -1;

  return (
    <AuthContext.Provider
      value={{
        authenticationToken,
        backendToken,
        userId,
        expiresIn,
        loading,
        userInfo,
      }}
    >
      {loading && !is_login ? <Loader /> : children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  return useContext(AuthContext);
}

export default AuthProvider;
