import React from "react";
import Loader from "../../../UI/Loader";

function QTStatComponent({ stats, title, qtfegoal, qtbegoal, qtcancelgoal }) {
  let currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let formatCurrency = (num) => {
    return num ? currencyFormatter.format(num).replace(".00", "") : "$0";
  };

  let now = new Date();
  let quarter = Math.floor(now.getMonth() / 3);
  let firstDate = new Date(now.getFullYear(), quarter * 3, 1);
  let differenceInTime = new Date().getTime() - firstDate.getTime();
  // To calculate the no. of days between two dates
  let differenceInDays = differenceInTime / (1000 * 3600 * 24);
  let coefficient = (differenceInDays * 100) / 90;
  coefficient = coefficient > 100 ? 1 : coefficient / 100;

  return (
    <>
      {!stats && <Loader />}{" "}
      {stats && (
        <>
          <div className="extra" style={{ background: "black" }}>
            <div className="card" style={{ width: "100%" }}>
              <p className="numbers">{!title}</p>
            </div>
          </div>
          <div
            className="container sales"
            style={{
              gridTemplateColumns: "28% 28% 36%",
              background: "#262424",
              color: "white",
            }}
          >
            <div className="card">
              <p className="numbers">
                <p
                  style={{
                    color:
                      (+stats.Stats.AmountNewSales +
                        +stats.Stats.AmountReactivations +
                        +stats.Stats.AmountReferrals) *
                        coefficient >
                      23400 * coefficient
                        ? "rgb(72, 128, 82)"
                        : "rgb(158, 94, 94)",
                  }}
                >
                  {formatCurrency(
                    +stats.Stats.AmountNewSales +
                      +stats.Stats.AmountReactivations +
                      +stats.Stats.AmountReferrals
                  )}
                </p>{" "}
                <p className="goal">QT GOAL {formatCurrency(qtfegoal)}</p>
              </p>
              <span className="title">FrontEnd</span>
              <span className="subtitle">
                (New Sales + Reactivations + Referrals)
              </span>
            </div>
            <div className="card">
              <p className="numbers">
                <p
                  style={{
                    color:
                      (+stats.Stats.AmountUpsell +
                        +stats.Stats.AmountAdditional) *
                        coefficient >
                      23400 * coefficient
                        ? "rgb(72, 128, 82)"
                        : "rgb(158, 94, 94)",
                  }}
                >
                  {formatCurrency(
                    +stats.Stats.AmountUpsell + +stats.Stats.AmountAdditional
                  )}
                </p>{" "}
                <p className="goal">QT GOAL {formatCurrency(qtbegoal)}</p>
              </p>
              <span className="title">BackEnd</span>
              <span className="subtitle">(Upsells and Additional Offices)</span>
            </div>
            <div className="card">
              <p
                className="totals sales"
                style={{
                  marginTop: "20px",
                  background: "transparent",
                }}
              >
                <p
                  style={{
                    marginBottom: "-10px",
                    color:
                      (+stats.Stats.AmountUpsell +
                        +stats.Stats.AmountNewSales +
                        +stats.Stats.AmountAdditional +
                        +stats.Stats.AmountReactivations +
                        +stats.Stats.AmountReferrals) *
                        coefficient >
                      59400 * coefficient
                        ? "rgb(72, 128, 82)"
                        : "rgb(158, 94, 94)",
                  }}
                >
                  {formatCurrency(
                    +stats.Stats.AmountUpsell +
                      +stats.Stats.AmountNewSales +
                      +stats.Stats.AmountAdditional +
                      +stats.Stats.AmountReactivations +
                      +stats.Stats.AmountReferrals
                  )}
                </p>{" "}
                <p className="goal">
                  QT GOAL {formatCurrency(qtfegoal + qtbegoal)}
                </p>
              </p>
              <span className="subtitle" style={{ marginTop: "20px" }}>
                (Backend + Frontend + Referrals)
              </span>
            </div>
          </div>
          <div className="extra" style={{ background: "#1A1818" }}>
            <div className="card" style={{ width: "100%" }}>
              <p className="numbers">
                {Array.from(
                  new Set(
                    Object.keys(stats.QuarterlyFE).concat(
                      Object.keys(stats.QuarterlyBE)
                    )
                  )
                )
                  .reverse()
                  .map((e) => {
                    console.log(e);
                    return (
                      e +
                      ":" +
                      " FE " +
                      formatCurrency(stats.QuarterlyFE[e]) +
                      " / BE " +
                      formatCurrency(stats.QuarterlyBE[e]) +
                      "  |  "
                    );
                  })}
              </p>
            </div>
          </div>
          <div
            className="container cancellations"
            style={{ background: "#262424", color: "white" }}
          >
            <div className="card">
              <p
                className="numbers"
                style={{
                  color:
                    stats.Stats.AmountCancellations * coefficient <
                    12000 * coefficient
                      ? "rgb(72, 128, 82)"
                      : "rgb(158, 94, 94)",
                }}
              >
                {formatCurrency(stats.Stats.AmountFullCancellations)}
              </p>
              <span className="title">Full GM Cancellations</span>
            </div>
            <div className="card">
              <p
                className="numbers"
                style={{
                  color:
                    stats.Stats.AmountCancellations * coefficient <
                    12000 * coefficient
                      ? "rgb(72, 128, 82)"
                      : "rgb(158, 94, 94)",
                }}
              >
                {formatCurrency(stats.Stats.AmountPartialCancellations)}
              </p>
              <span className="title">Cancelled Products</span>
            </div>
            <div className="card">
              <p className="totals">
                <p
                  style={{
                    marginBottom: "-20px",
                    color:
                      stats.Stats.AmountCancellations * coefficient <
                      12000 * coefficient
                        ? "rgb(72, 128, 82)"
                        : "rgb(158, 94, 94)",
                  }}
                >
                  {formatCurrency(stats.Stats.AmountCancellations)}
                </p>{" "}
                <p className="goal">
                  QT GOAL &gt; {formatCurrency(qtcancelgoal)}
                </p>
              </p>
              <span className="title" style={{ display: "block" }}>
                Total Lost
              </span>
            </div>
          </div>
          <div className="extra" style={{ background: "#1A1818" }}>
            <div className="card" style={{ width: "100%" }}>
              <p className="numbers">
                {Array.from(
                  new Set(
                    Object.keys(stats.QuarterlyCancelledGMs).concat(
                      Object.keys(stats.QuarterlyCancelledProds)
                    )
                  )
                )
                  .reverse()
                  .map((e) => {
                    console.log(e);
                    return (
                      e +
                      ":" +
                      " FULL MEMBERSHIPS " +
                      formatCurrency(stats.QuarterlyCancelledGMs[e]) +
                      " / PRODS " +
                      formatCurrency(stats.QuarterlyCancelledProds[e]) +
                      "  |  "
                    );
                  })}
              </p>
            </div>
          </div>
          <div className="container" style={{ background: "#21313B" }}>
            <div className="card" style={{ padding: "10px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                <p
                  className="numbers"
                  style={{
                    marginTop: "0",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  {formatCurrency(+stats.Stats.AmountRefunds)}
                </p>
                <p
                  className="title"
                  style={{
                    marginTop: "-10px",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  Refunds
                </p>
              </div>
            </div>
            <div className="card" style={{ marginRight: "20px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                <p
                  className="numbers"
                  style={{
                    marginTop: "0",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  {formatCurrency(+stats.Stats.AmountCredits)}
                </p>
                <p
                  className="title"
                  style={{
                    marginTop: "-10px",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  Credits
                </p>
              </div>
            </div>
            <div className="card" style={{ marginTop: "-20px" }}>
              <p
                className="totals"
                style={{
                  color:
                    (+stats.Stats.AmountUpsell +
                      +stats.Stats.AmountNewSales +
                      +stats.Stats.AmountAdditional +
                      +stats.Stats.AmountReactivations +
                      +stats.Stats.AmountReferrals -
                      +stats.Stats.AmountCancellations) *
                      coefficient >
                    47400 * coefficient
                      ? "rgb(72, 128, 82)"
                      : "rgb(158, 94, 94)",
                }}
              >
                {formatCurrency(
                  +stats.Stats.AmountUpsell +
                    +stats.Stats.AmountNewSales +
                    +stats.Stats.AmountAdditional +
                    +stats.Stats.AmountReactivations +
                    +stats.Stats.AmountReferrals -
                    +stats.Stats.AmountCancellations
                )}
              </p>
              <p className="title">
                QT GROWTH {formatCurrency(qtfegoal + qtbegoal - qtcancelgoal)}
              </p>
            </div>
          </div>
          <div className="extra" style={{ background: "#1A1818" }}>
            <div className="card" style={{ width: "100%" }}>
              <p className="numbers">
                {Array.from(
                  new Set(
                    Object.keys(stats.QuarterlyRefunds).concat(
                      Object.keys(stats.QuarterlyCredits)
                    )
                  )
                )
                  .reverse()
                  .map((e) => {
                    console.log(e);
                    return (
                      e +
                      ":" +
                      " Refunds " +
                      formatCurrency(stats.QuarterlyRefunds[e]) +
                      " / Credits " +
                      formatCurrency(stats.QuarterlyCredits[e]) +
                      "  |  "
                    );
                  })}
              </p>
            </div>
          </div>
          <div
            className="extra"
            style={{
              background: "#1A1818",
              height: "500px",
              width: "100%",
              display: "block",
            }}
          ></div>
        </>
      )}
    </>
  );
}

export default QTStatComponent;
