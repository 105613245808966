import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import { Row } from "react-bootstrap";

import Sidebar from "../../UI/Sidebar";
import { getTeams } from "../../SF";
import Loader from "../../UI/Loader";
import Filter from "../../UI/Filter";
import { getStorageValue } from "../../Utils";

const Teams = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  useEffect(() => {
    if (!data) {
      getTeams(context)
        .then(function (result) {
          setData(result);
          //console.log(result);
        })
        .finally(() => (context.isLoading = false));
    }
  }); // Initial page load

  try {
    if (getStorageValue("gargle_analytics_owner_id"))
      context.OwnerId = getStorageValue("gargle_analytics_owner_id");
  } catch (err) {
    context.OwnerId = null;
    window.localStorage.clear();
  }

  const columns = [
    "Account",
    "Membership",
    "Next Billing",
    "Last Task",
    "Next Task",
    "Next VP Task",
    "Next Director Task",
    "Overdue Tasks",
    "Active Products",
    "Active Discounts",
  ];

  const userOptionsOwners = [];
  if (data) {
    userOptionsOwners.push({ value: "null", label: "All Users" });

    for (const u of data.Owners) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
    }

    setTimeout(() => {
      window["reset"]();

      window["buildDataTable"](
        [
          { width: "100px", targets: 2 },
          { width: "100px", targets: 3 },
          { width: "100px", targets: 4 },
          { width: "100px", targets: 5 },
          { width: "150px", targets: 6 },
        ],
        [0, "asc"]
      );
    }, 500);
  }

  if (context.OwnerId)
    context.Owner = userOptionsOwners.find(
      (item) => item.value === context.OwnerId
    );

  return (
    <>
      <Sidebar />
      <div className="content">
        {!data && <Loader />}
        {data && (
          <>
            <Row>
              <Filter
                context={context}
                userOptionsOwners={userOptionsOwners}
                filter={(e, setShowFilter) => {
                  e.target.disabled = true;
                  e.target.innerHTML = "Loading...";
                  window.location.reload();
                }}
              />
              <div className="chart-container">
                <div className="cs-title">
                  {!context.OwnerId ||
                  !context.Owner ||
                  context.Owner.value === "null"
                    ? "All Directors"
                    : context.Owner.label}{" "}
                  - {data.Accounts.length} accounts
                </div>
                <table
                  id="main-table"
                  style={{
                    display: "none",
                    minWidth: "100%",
                    width: "100%",
                  }}
                  border="0"
                  className="display stripe row-border order-column"
                >
                  <thead>
                    <tr>
                      {columns.map((c) => {
                        return <th key={"col-" + c}>{c}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.Accounts.map((a) => {
                      return (
                        <tr key={"row-" + a.Name}>
                          <td>
                            {" "}
                            <a
                              href={
                                "https://gargleinc.lightning.force.com/" + a.Id
                              }
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              {a.Name}
                            </a>
                          </td>
                          <td>{a.MembershipStatus}</td>
                          <td>{a.NextBillingDate}</td>
                          <td>
                            {a.LastTask && (
                              <>
                                {" "}
                                <a
                                  href={
                                    "https://gargleinc.lightning.force.com/" +
                                    a.LastTask.Id
                                  }
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {a.LastTask.Subject}
                                  <br />
                                  {a.LastTask.ActivityDate}
                                </a>
                              </>
                            )}
                          </td>
                          <td>
                            {a.NextTask && (
                              <>
                                {" "}
                                <a
                                  href={
                                    "https://gargleinc.lightning.force.com/" +
                                    a.NextTask.Id
                                  }
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {a.NextTask.Subject}
                                  <br />
                                  {a.NextTask.ActivityDate}
                                </a>
                              </>
                            )}
                          </td>
                          <td>
                            {a.NextVPTask &&
                              a.NextVPTask.map((t) => {
                                return (
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      t.Id
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {t.Subject}
                                    <br />
                                    {t.ActivityDate}
                                  </a>
                                );
                              })}
                          </td>
                          <td>
                            {a.NextDirectorTask &&
                              a.NextDirectorTask.map((t) => {
                                return (
                                  <a
                                    href={
                                      "https://gargleinc.lightning.force.com/" +
                                      t.Id
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {t.Subject}
                                    <br />
                                    {t.ActivityDate}
                                  </a>
                                );
                              })}
                          </td>
                          <td>
                            {a.OverdueTasks &&
                              a.OverdueTasks.map((t) => {
                                return (
                                  <p key={t.Id}>
                                    <a
                                      href={
                                        "https://gargleinc.lightning.force.com/" +
                                        t.Id
                                      }
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      {t.Subject}
                                      <br />
                                      {t.ActivityDate}
                                    </a>
                                    <br />
                                    <br />
                                  </p>
                                );
                              })}
                          </td>
                          <td>
                            {a.ActiveProducts && (
                              <>
                                {a.ActiveProducts.map((t) => {
                                  return (
                                    t.Product2 && (
                                      <p key={t.Id}>
                                        {t.Product2.Name} - $
                                        {t.Calculated_Amount_Actual__c}
                                      </p>
                                    )
                                  );
                                })}
                                {a.ActiveProducts &&
                                  a.ActiveProducts.length > 0 &&
                                  a.ActiveProducts[0].Order && (
                                    <>
                                      <p
                                        style={{
                                          width: "100%",
                                          height: "1px",
                                          background: "gray",
                                          margin: "5px 0",
                                        }}
                                      ></p>
                                      <p style={{ width: "100%" }}>
                                        <a
                                          href={
                                            "https://gargleinc.lightning.force.com/" +
                                            a.ActiveProducts[0].OrderId
                                          }
                                          target={"_blank"}
                                          rel="noreferrer"
                                        >
                                          {
                                            a.ActiveProducts[0].Order
                                              .Order_Label__c
                                          }{" "}
                                          - ${" "}
                                          {
                                            a.ActiveProducts[0].Order
                                              .Total_Amount__c
                                          }
                                        </a>
                                      </p>
                                    </>
                                  )}
                              </>
                            )}
                          </td>
                          <td>
                            {a.ActiveDiscounts &&
                              a.ActiveDiscounts.map((t) => {
                                return (
                                  t.Product__c && (
                                    <p key={t.Id}>
                                      {t.Product__r.Name} - ${t.Amount__c}
                                    </p>
                                  )
                                );
                              })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Teams;
