import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { askAI, sfGlobalSearch } from "./../API/index";
import { useAuthContext } from "./../API/AuthContext";
import { logout } from "./../Utils";
import { useNavigate } from "react-router-dom";
import TypingEffect from "./TypingEffect";
import { UserContext } from "../../App";

const ChatWidget = () => {
  const { loading, backendToken, userInfo } = useAuthContext();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [chatLoading, setChatLoading] = useState(false);
  const [rows, setRows] = useState(1);
  const [error, setError] = useState(null);
  const [loadingText, setLoadingText] = useState("Thinking...");
  const context = useContext(UserContext);

  // Salesforce auto-complete states
  const [salesforceQuery, setSalesforceQuery] = useState("");
  const [salesforceSuggestions, setSalesforceSuggestions] = useState([]);
  const [selectedContext, setSelectedContext] = useState(null);

  const [prettifiedContext, setPrettifiedContext] = useState(null);
  const [loadingPrettified, setLoadingPrettified] = useState(false);

  const maxChars = 500;
  const inputRef = useRef(null);
  const chatBoxRef = useRef(null);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const initialMessage = urlParams.get("initial_message");

  if (error) console.log(error);

  const formatObject = useCallback((obj) => {
    const keysToRemove = ["id", "url", "activeusers"];

    const cleanObject = (input) => {
      if (Array.isArray(input)) {
        return input.map((item) => cleanObject(item));
      } else if (typeof input === "object" && input !== null) {
        return Object.fromEntries(
          Object.entries(input)
            .filter(([key]) => !keysToRemove.includes(key.toLowerCase()))
            .map(([key, value]) => [key, cleanObject(value)])
        );
      } else {
        return input;
      }
    };

    return JSON.stringify(cleanObject(obj), null, 2); // Prettify JSON for readability
  }, []);

  useEffect(() => {
    if (!loading && !backendToken) {
      logout(navigate);
    }
  }, [navigate, backendToken, loading]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        if (!loading && backendToken) {
          setMessages([
            {
              sender: "bot",
              text: `Hello ${userInfo.userFullName}! How can I assist you today?`,
            },
          ]);
          if (initialMessage) {
            const formattedMessage = decodeURIComponent(initialMessage)
              .replace(/\\n/g, "\n")
              .replace(/%0A/g, "\n")
              .replace(/\r/g, "");
            setInput(formattedMessage);
          }
        }

        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      } catch (err) {
        setError(err.message);
        console.log(err);
      }
    };

    initialLoad();
  }, [
    loading,
    backendToken,
    initialMessage,
    context.CONTEXT_DATA,
    formatObject,
    userInfo,
    prettifiedContext,
  ]);

  useEffect(() => {
    if (chatLoading) {
      let index = 0;
      const loadingMessages = [
        "Sending request & data context...",
        "Thinking...",
        "Analyzing...",
        "Processing...",
        "Generating response...",
        "Almost there...",
        "Finalizing...",
      ];

      const interval = setInterval(() => {
        index = (index + 1) % loadingMessages.length;
        setLoadingText(loadingMessages[index]);
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [chatLoading]);

  useEffect(() => {
    if (chatBoxRef && chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  // Debounce the search query
  useEffect(() => {
    const fetchSalesforceSuggestions = async (query) => {
      if (!query) {
        setSalesforceSuggestions([]);
        return;
      }

      try {
        const response = await sfGlobalSearch(query, context);
        // Assume data.results is an array of Salesforce objects
        if (response) setSalesforceSuggestions(response);
        else setSalesforceSuggestions([]);
      } catch (err) {
        console.error("Error fetching Salesforce suggestions:", err);
      }
    };

    const timer = setTimeout(() => {
      fetchSalesforceSuggestions(salesforceQuery);
    }, 300);
    return () => clearTimeout(timer);
  }, [salesforceQuery, context]);

  const handleSuggestionClick = (suggestion) => {
    setSelectedContext(suggestion);
    setSalesforceQuery(
      suggestion.attributes.type +
        ": " +
        (suggestion.Name || suggestion.FirstName + " " + suggestion.LastName) ||
        ""
    );
    setSalesforceSuggestions([]);
  };

  async function handleSendMessage(inimsg = null) {
    if (!input.trim() && !inimsg) return;

    const newMessage = { sender: "you", text: input || inimsg };
    setMessages([...messages, newMessage]);
    clearText();
    setChatLoading(true);

    try {
      // Pass the selectedContext to your askAI function as needed
      let msg = input || inimsg;

      msg = context.CONTEXT_DATA
        ? `
        Today's date is ${new Date().toJSON()}

        -----------------------------------CONTEXT-----------------------------------

        ${msg}

        -----------------------------------DATA-----------------------------------

        Please use this data if relevant to user prompt. Ignore unrelated topics. Do not guess user intent.
        Important: Do not introduce new information. Only summarize the provided data. 
        If something is missing from the data, state that it is missing. Do not invent data, user requests, or context.
        In this data, any field that starts with Amount refers to a dollar amount (USD), not a count of transactions. 
        Fields that start with Count are counts of products or events. 
        For example, AmountNewSales is the total dollar value of new sales, not the number of new sales.

        ${formatObject(context.CONTEXT_DATA)}

        Data was filtered using the following rules. 
        Use this to give context to the data and to be able to respond questions.
        These are internal field names. If you reference them, don't reference them with their internal name, but instead using a friendly name.
        For example, it may be a date, the ID of a user, etc:
        ${formatObject(context.CONTEXT_FILTER)}
      `
        : msg;
      const response = await askAI(
        msg,
        selectedContext && selectedContext.Id,
        selectedContext && "salesforce"
      );
      let botReply = response.results;
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: botReply },
      ]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Oops! Something went wrong." },
      ]);
    } finally {
      setChatLoading(false);
    }
  }

  function clearText() {
    setInput("");
    setRows(1);

    if (inputRef.current) {
      inputRef.current.blur();
      setTimeout(() => inputRef.current.focus(), 0);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length > maxChars) return;
    setInput(value);

    const lineCount = value.split("\n").length;
    setRows(lineCount > 4 ? 4 : lineCount);
  };

  return (
    <div className="chat-container">
      <style>
        {`
          @keyframes fadeInOut {
            0% { opacity: 0; }
            50% { opacity: 1; }
            100% { opacity: 0; }
          }
          .loading {
            animation: fadeInOut 4s ease-in-out infinite;
            font-style: italic;
            text-align: left;
            margin-top: 10px;
            margin-left: 10px;
          }
          .autocomplete-container {
            margin-bottom: 15px;
            position: relative;
          }
          .autocomplete-input {
            width: 100%;
            padding: 8px;
            border: 0 !important;
            border-bottom: 1px dotted gray !important;
          }
          .autocomplete-suggestions {
            position: absolute;
            top: 36px;
            left: 0;
            right: 0;
            border: 1px solid #ccc;
            background: #fff;
            z-index: 1000;
            max-height: 150px;
            overflow-y: auto;
          }
          .suggestion-item {
            padding: 8px;
            cursor: pointer;
          }
          .suggestion-item:hover {
            background: #eee;
          }
        `}
      </style>

      {/* Salesforce Auto-complete Box */}
      <div className="autocomplete-container">
        <input
          type="text"
          className="autocomplete-input"
          placeholder="Context: Current Window (Type to search an Account, Lead, etc...)"
          value={salesforceQuery}
          onChange={(e) => setSalesforceQuery(e.target.value)}
        />
        {salesforceSuggestions && salesforceSuggestions.length > 0 && (
          <div className="autocomplete-suggestions">
            {salesforceSuggestions.map((suggestion) => (
              <div
                key={suggestion.Id}
                className="suggestion-item"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.attributes.type +
                  ": " +
                  (suggestion.Name ||
                    suggestion.FirstName + " " + suggestion.LastName)}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.sender === "bot" ? (
              <TypingEffect text={msg.text} speed={5} />
            ) : (
              <div>{msg.text}</div>
            )}
          </div>
        ))}
        {chatLoading && <div className="loading">{loadingText}</div>}
      </div>

      <div className="input-container">
        <textarea
          value={input}
          onChange={handleInputChange}
          placeholder="Type your message..."
          className="input"
          ref={inputRef}
          onKeyDown={handleKeyDown}
          rows={rows}
          style={{ resize: "none", overflow: "hidden" }}
        />
        <button onClick={handleSendMessage} className="send-button">
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWidget;
