import React from "react";
import attentionImg from "../../../attention.png";

const AttentionImg = ({ title }) => {
  return (
    <div style={{ display: "inline-block", width: "100%" }}>
      <h5 style={{ marginBottom: "70px", width: "100%", textAlign: "center" }}>
        {title}
      </h5>
      <img
        src={attentionImg}
        alt="Attention!"
        style={{
          display: "block",
          width: "20%",
          margin: "0 auto",
        }}
        title={"No data. This requires your attention!"}
      />
      <h5 style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
        No records found!
      </h5>
    </div>
  );
};

export default AttentionImg;
