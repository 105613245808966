import React, { useContext, useEffect, useState, useMemo } from "react";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import { sfQuery } from "../../API";
import { useParams } from "react-router";
import dayjs from "dayjs"; // Ensure you have dayjs installed
import isBetween from "dayjs/plugin/isBetween"; // Import isBetween plugin
dayjs.extend(isBetween); // Extend dayjs with isBetween

const SalesLifecycleDashboard = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [gmOrders, setGmOrders] = useState([]);
  const [miOrders, setMiOrders] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("6 Months"); // Added select state
  const context = useContext(UserContext);

  let { owId, sidebar } = useParams();

  // Refresh Function to Fetch Data
  const refresh = async () => {
    if (context.accessToken !== null && !context.isLoading) {
      context.isLoading = true;
      setLoading(true); // Start loading

      try {
        // Fetch Opportunities
        const opps = await sfQuery(
          "Opportunity",
          "Id, Name, AccountId, RecordType.Name, CloseDate, Total_MRR__c, Total_One_Offs__c, StageName",
          `CloseDate >= LAST_N_MONTHS:6 AND StageName = 'Closed Won'`,
          context
        );
        setOpportunities(opps); // Assign directly

        // Fetch GM Orders
        const gm = await sfQuery(
          "Order",
          "Id, AccountId, Payment_Date_new__c, Total_Amount__c",
          `Order_Label__c LIKE 'GM-%' AND Status='Activated' AND (NOT Account.Name LIKE 'Test %') AND Account.RecordType.Name LIKE '%Customer%'`,
          context
        );
        setGmOrders(gm); // Assign directly

        // Fetch MI Orders
        const mi = await sfQuery(
          "Order",
          "Id, AccountId, Order_Label__c, Payment_Date_new__c, Total_Amount__c",
          `Order_Label__c LIKE 'MI-%' AND Payment_Date_new__c >= LAST_N_MONTHS:6 AND (NOT Account.Name LIKE 'Test %') AND Account.RecordType.Name LIKE '%Customer%' ORDER BY Payment_Date_new__c DESC`,
          context
        );
        setMiOrders(mi); // Assign directly

        setError(false);
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        context.isLoading = false;
        setLoading(false); // End loading
      }
    }
  };

  // Fetch Data on Component Mount
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Generate Selected Months Labels based on selectedPeriod
  const selectedMonths = useMemo(() => {
    const months = [];
    const today = dayjs();
    if (selectedPeriod === "6 Months") {
      for (let i = 5; i >= 0; i--) {
        months.push(today.subtract(i, "month").format("MMMM YYYY"));
      }
    } else if (selectedPeriod === "Last 3 Months") {
      for (let i = 2; i >= 0; i--) {
        months.push(today.subtract(i, "month").format("MMMM YYYY"));
      }
      months.push(today.format("MMMM YYYY")); // Include this month
    } else if (selectedPeriod === "Last Month") {
      months.push(today.subtract(1, "month").format("MMMM YYYY"));
      months.push(today.format("MMMM YYYY")); // Include this month
    } else if (selectedPeriod === "This Month") {
      months.push(today.format("MMMM YYYY"));
    }
    return months;
  }, [selectedPeriod]);

  // Define Date Range based on selectedPeriod
  const dateRange = useMemo(() => {
    const today = dayjs();
    if (selectedPeriod === "6 Months") {
      return {
        start: today.subtract(5, "month").startOf("month"),
        end: today.endOf("month"),
      };
    } else if (selectedPeriod === "Last 3 Months") {
      return {
        start: today.subtract(3, "month").startOf("month"),
        end: today.endOf("month"),
      };
    } else if (selectedPeriod === "Last Month") {
      const lastMonth = today.subtract(1, "month");
      return {
        start: lastMonth.startOf("month"),
        end: today.endOf("month"),
      };
    } else if (selectedPeriod === "This Month") {
      return {
        start: today.startOf("month"),
        end: today.endOf("month"),
      };
    }
    return {
      start: today.subtract(5, "month").startOf("month"),
      end: today.endOf("month"),
    };
  }, [selectedPeriod]);

  // Helper Function to Determine if a Number is a Multiple of 50
  const isMultipleOfFifty = (amount) => {
    return amount % 50 === 0;
  };

  // Function to get tooltip text for notes
  const getNoteTooltip = (note) => {
    switch (note) {
      case "Proration":
        return "This payment is a proration based on the billing cycle.";
      case "Temporary Discount":
        return "This payment includes a temporary discount.";
      case "Delayed Billing":
        return "This payment was delayed a month or more after the Opportunity.";
      default:
        return "";
    }
  };

  // Processed Data Organized by Opportunity and MI Orders
  const processedData = useMemo(() => {
    // Filter Opportunities based on selectedPeriod
    const filteredOpportunities = opportunities.filter((opp) => {
      const closeDate = dayjs(opp.CloseDate, "YYYY-MM-DD");
      return closeDate.isBetween(dateRange.start, dateRange.end, null, "[]");
    });

    // Map GM Orders by AccountId and Payment_Date_new__c month
    const gmOrdersMap = gmOrders.reduce((acc, order) => {
      const accountId = order.AccountId;
      const paymentMonth = dayjs(
        order.Payment_Date_new__c,
        "YYYY-MM-DD"
      ).format("MMMM YYYY");
      if (!acc[accountId]) acc[accountId] = {};
      if (!acc[accountId][paymentMonth]) acc[accountId][paymentMonth] = [];
      acc[accountId][paymentMonth].push(order);
      return acc;
    }, {});

    // Map MI Orders by AccountId and Payment_Date_new__c month
    const miOrdersMap = miOrders.reduce((acc, order) => {
      const accountId = order.AccountId;
      const paymentMonth = dayjs(
        order.Payment_Date_new__c,
        "YYYY-MM-DD"
      ).format("MMMM YYYY");
      if (!acc[accountId]) acc[accountId] = {};
      if (!acc[accountId][paymentMonth]) acc[accountId][paymentMonth] = [];
      acc[accountId][paymentMonth].push(order);
      return acc;
    }, {});

    // Classify Opportunities and associate MI Orders
    const data = filteredOpportunities.map((opp) => {
      const frontEndTypes = [
        "Lead Sale",
        "Reactivation",
        "Additional Office",
        "Director Referral",
        "VP Referral",
      ];
      const backEndTypes = ["Up Sell", "Product Activation"];

      let classification = "Back End - Pricing Update";
      let type = "Pricing Update";

      if (frontEndTypes.includes(opp.RecordType.Name)) {
        classification = "Front End";
      } else if (backEndTypes.includes(opp.RecordType.Name)) {
        classification = "Back End - Up Sell";
        if (opp.RecordType.Name === "Up Sell") {
          type = "Up Sell";
        }
      }

      // For Front End, check GM Order
      let hasDiscount = false;
      if (classification === "Front End") {
        const accountGMs = gmOrdersMap[opp.AccountId] || {};
        const oppMonth = dayjs(opp.CloseDate, "YYYY-MM-DD").format("MMMM YYYY");
        const relatedGMs = accountGMs[oppMonth] || [];
        if (relatedGMs.length > 0) {
          // Assuming one GM order per month per account
          const gmTotal = parseFloat(relatedGMs[0].Total_Amount__c || 0);
          const oppTotal =
            parseFloat(opp.Total_MRR__c || 0) +
            parseFloat(opp.Total_One_Offs__c || 0);
          if (gmTotal < oppTotal) {
            hasDiscount = true;
          }
        } else {
          // No GM order found for this month, assume discount
          hasDiscount = true;
        }
      }

      // Prepare MI Orders for the selected months
      const oppCloseDate = dayjs(opp.CloseDate, "YYYY-MM-DD");
      const oppCloseMonth = oppCloseDate.format("MMMM YYYY");
      const allMiOrders = miOrdersMap[opp.AccountId] || {};
      const miOrdersForOpp = {};

      selectedMonths.forEach((month) => {
        const miInMonth = (allMiOrders && allMiOrders[month]) || [];
        if (classification === "Front End") {
          // Front End: Only MI Orders on or after CloseDate
          const relevantMIs = miInMonth.filter(
            (mi) =>
              dayjs(mi.Payment_Date_new__c, "YYYY-MM-DD").isAfter(
                oppCloseDate
              ) ||
              dayjs(mi.Payment_Date_new__c, "YYYY-MM-DD").isSame(
                oppCloseDate,
                "day"
              )
          );
          if (relevantMIs.length > 0) {
            miOrdersForOpp[month] = relevantMIs;
          }
        } else {
          // Back End: MI Orders at any time
          if (miInMonth.length > 0) {
            miOrdersForOpp[month] = miInMonth;
          }
        }
      });

      // Determine if any MI Order is less than the Opportunity amount
      let hasLowerMI = false;
      if (classification === "Front End") {
        const oppTotal =
          parseFloat(opp.Total_MRR__c || 0) +
          parseFloat(opp.Total_One_Offs__c || 0);
        for (const month in miOrdersForOpp) {
          for (const mi of miOrdersForOpp[month]) {
            const miAmount = parseFloat(mi.Total_Amount__c || 0);
            if (miAmount < oppTotal) {
              hasLowerMI = true;
              break;
            }
          }
          if (hasLowerMI) break;
        }
      }

      // Determine Notes based on MI Orders (only for Front End)
      let notes = "";
      if (classification === "Front End") {
        // Find the earliest MI Order on or after CloseDate
        const allMiOrdersArray = selectedMonths.reduce((acc, month) => {
          if (miOrdersForOpp[month]) {
            acc.push(...miOrdersForOpp[month]);
          }
          return acc;
        }, []);

        const firstMI =
          allMiOrdersArray.length > 0 ? allMiOrdersArray[0] : null;

        if (firstMI) {
          const miAmount = parseFloat(firstMI.Total_Amount__c || 0);
          const expectedMonthlyAmount =
            parseFloat(opp.Total_MRR__c || 0) +
            parseFloat(opp.Total_One_Offs__c || 0);
          const discountAmount = expectedMonthlyAmount - miAmount;

          // Temporary Discount Detection: If discountAmount is a multiple of 50
          if (isMultipleOfFifty(discountAmount)) {
            notes += "Temporary Discount";
          }

          // Proration Detection: MI Payment Date is after the 1st of the month and no Temporary Discount
          const miPaymentDate = dayjs(
            firstMI.Payment_Date_new__c,
            "YYYY-MM-DD"
          );
          const isAfterFirst = miPaymentDate.date() > 1;

          if (
            isAfterFirst &&
            discountAmount > 0 &&
            !notes.includes("Temporary Discount")
          ) {
            notes += notes ? ", Proration" : "Proration";
          }

          // Check for Delayed Billing: MI Orders in a later month than CloseDate
          const paymentDate = dayjs(firstMI.Payment_Date_new__c, "YYYY-MM-DD");
          const closeDate = dayjs(opp.CloseDate, "YYYY-MM-DD");
          const delayedBilling = paymentDate.isAfter(
            closeDate.add(1, "month").startOf("month")
          );

          if (delayedBilling) {
            notes += notes ? ", Delayed Billing" : "Delayed Billing";
          }
        }
      }

      return {
        id: opp.Id,
        name: opp.Name,
        classification,
        type,
        hasDiscount,
        hasLowerMI, // New field for visual indicator
        hasMIOrders: Object.keys(miOrdersForOpp).length > 0, // Updated to check any MI Orders
        closeDate: dayjs(opp.CloseDate, "YYYY-MM-DD").format("MMMM YYYY"),
        closeMonth: dayjs(opp.CloseDate, "YYYY-MM-DD").format("MMMM YYYY"),
        opportunityAmount:
          parseFloat(opp.Total_MRR__c || 0) +
          parseFloat(opp.Total_One_Offs__c || 0),
        miOrders: miOrdersForOpp, // { "Month Year": [miOrders] }
        notes: notes.trim(), // Notes based on MI Orders
      };
    });

    // Sort data descending by CloseDate
    data.sort(
      (a, b) =>
        dayjs(b.closeDate, "MMMM YYYY").unix() -
        dayjs(a.closeDate, "MMMM YYYY").unix()
    );

    return data;
  }, [opportunities, gmOrders, miOrders, selectedMonths, dateRange]);

  // Filtered Data based on Search Query
  const filteredData = useMemo(() => {
    if (!searchQuery.trim()) return processedData;

    const query = searchQuery.toLowerCase();

    return processedData.filter((opp) => {
      const oppNameMatch = opp.name.toLowerCase().includes(query);
      const classificationMatch = opp.classification
        .toLowerCase()
        .includes(query);
      const typeMatch = opp.type.toLowerCase().includes(query);
      const notesMatch = opp.notes.toLowerCase().includes(query);

      // Check if any MI Orders match the query
      const miOrdersMatch = Object.values(opp.miOrders).some((miList) =>
        miList.some((mi) => mi.Order_Label__c.toLowerCase().includes(query))
      );

      return (
        oppNameMatch ||
        classificationMatch ||
        typeMatch ||
        notesMatch ||
        miOrdersMatch
      );
    });
  }, [processedData, searchQuery]);

  // Separate Data by Classification
  const frontEndData = useMemo(() => {
    return filteredData.filter((opp) => opp.classification === "Front End");
  }, [filteredData]);

  const backEndUpSellData = useMemo(() => {
    return filteredData.filter(
      (opp) => opp.classification === "Back End - Up Sell"
    );
  }, [filteredData]);

  const backEndPricingUpdateData = useMemo(() => {
    return filteredData.filter(
      (opp) => opp.classification === "Back End - Pricing Update"
    );
  }, [filteredData]);

  if (context.accessToken === null) return <></>;
  if (error) return <ErrorMessage />;
  if (loading) return <Loader />; // Show Loader while loading

  // Helper Component for Tables
  const OpportunitiesTable = ({ title, data, isFrontEnd }) => {
    // Calculate Per-Table Totals
    const tableTotals = useMemo(() => {
      let totalSold = 0;
      const totalMiPerMonth = {};

      selectedMonths.forEach((month) => {
        totalMiPerMonth[month] = 0;
      });

      data.forEach((opp) => {
        totalSold += opp.opportunityAmount;
        selectedMonths.forEach((month) => {
          if (opp.miOrders[month]) {
            opp.miOrders[month].forEach((mi) => {
              totalMiPerMonth[month] += parseFloat(mi.Total_Amount__c || 0);
            });
          }
        });
      });

      return { totalSold, totalMiPerMonth };
    }, [data, selectedMonths]);

    return (
      <div style={{ marginBottom: "40px" }}>
        <h2>{title}</h2>
        {data.length > 0 ? (
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                {/* Totals Row */}
                <tr className="totals-row">
                  <td
                    colSpan="2"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Total Sold:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    ${tableTotals.totalSold.toLocaleString()}
                  </td>
                  {selectedMonths.map((month) => (
                    <td key={month} style={{ fontWeight: "bold" }}>
                      ${tableTotals.totalMiPerMonth[month].toLocaleString()}
                    </td>
                  ))}
                  <td style={{ fontWeight: "bold" }}>Notes</td>{" "}
                  {/* Single Notes Column */}
                </tr>
                {/* Header Row */}
                <tr>
                  <th>Opportunity Name</th>
                  <th>Close Date</th>
                  <th>Opportunity Amount</th>
                  {selectedMonths.map((month) => (
                    <th key={month}>{month}</th>
                  ))}
                  <th>Notes</th> {/* Single Notes Column */}
                </tr>
              </thead>
              <tbody>
                {data.map((opp) => (
                  <tr
                    key={opp.id}
                    className={
                      opp.hasLowerMI
                        ? "row-error" // Opportunities with MI Orders below amount
                        : opp.hasMIOrders
                        ? "row-success" // Opportunities with MI Orders meeting/exceeding amount
                        : "row-attention" // Opportunities without any MI Orders
                    }
                  >
                    <td>
                      <a
                        href={`https://gargleinc.lightning.force.com/${opp.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#0070d2" }}
                      >
                        <strong>{opp.name}</strong>
                      </a>
                      {/* New Visual Indicator */}
                      {opp.hasMIOrders ? (
                        opp.hasLowerMI ? (
                          <span
                            className="mi-warning"
                            title="Some MI Orders are less than the Opportunity amount"
                            aria-label="MI Orders below Opportunity amount"
                          >
                            {" "}
                            🔻
                          </span>
                        ) : (
                          <span
                            className="mi-success"
                            title="All MI Orders meet or exceed the Opportunity amount"
                            aria-label="All MI Orders meet or exceed Opportunity amount"
                          >
                            {" "}
                            ✅
                          </span>
                        )
                      ) : (
                        <span
                          className="mi-attention"
                          title="No MI Orders associated with this Opportunity"
                          aria-label="No MI Orders associated with Opportunity"
                        >
                          {" "}
                          ⚠️
                        </span>
                      )}
                    </td>
                    <td>{opp.closeDate}</td>
                    <td>${opp.opportunityAmount.toLocaleString()}</td>
                    {selectedMonths.map((month) => {
                      const miInMonth = opp.miOrders[month] || [];
                      const isHighlightMonth = month === opp.closeMonth;

                      return (
                        <td
                          key={month}
                          style={{ verticalAlign: "top" }}
                          className={isHighlightMonth ? "highlight-month" : ""}
                        >
                          {miInMonth.length > 0 ? (
                            <div>
                              <ul>
                                {miInMonth.map((mi) => {
                                  const miAmount = parseFloat(
                                    mi.Total_Amount__c || 0
                                  );
                                  const isDifferentAmount =
                                    isFrontEnd &&
                                    miAmount !== opp.opportunityAmount;
                                  const isSameMonthBackEnd =
                                    !isFrontEnd && isHighlightMonth;

                                  return (
                                    <li
                                      key={mi.Id}
                                      className={
                                        isDifferentAmount
                                          ? "mi-different-amount"
                                          : isSameMonthBackEnd
                                          ? "mi-highlight"
                                          : ""
                                      }
                                    >
                                      {mi.Order_Label__c} - $
                                      {miAmount.toLocaleString()}
                                    </li>
                                  );
                                })}
                              </ul>
                              {/* Subtotal for MI Orders in the month */}
                              <strong>
                                Total MI Amount: $
                                {miInMonth
                                  .reduce(
                                    (sum, mi) =>
                                      sum + parseFloat(mi.Total_Amount__c || 0),
                                    0
                                  )
                                  .toLocaleString()}
                              </strong>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      );
                    })}
                    {/* Notes Column */}
                    <td>
                      {opp.notes
                        ? opp.notes.split(", ").map((note, index) => (
                            <span
                              key={index}
                              className={`note-badge note-${note
                                .replace(" ", "-")
                                .toLowerCase()}`}
                              title={getNoteTooltip(note)}
                            >
                              {note}
                            </span>
                          ))
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No data available.</p>
        )}
      </div>
    );
  };

  return (
    <>
      {!error && (
        <>
          {!sidebar && <Sidebar />}
          <div className={owId ? "contentWOS dashboard" : "content dashboard"}>
            <div style={{ height: "100%", minHeight: "100%" }}>
              <div className="chart-container">
                <h1>Sales Lifecycle Dashboard</h1>
                {/* Removed General Totals Section */}
                {/* Select Dropdown for Period */}
                <div style={{ marginBottom: "20px" }}>
                  <label
                    htmlFor="period-select"
                    style={{ marginRight: "10px" }}
                  >
                    Select Period:
                  </label>
                  <select
                    id="period-select"
                    value={selectedPeriod}
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                    style={{
                      padding: "8px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <option value="6 Months">6 Months</option>
                    <option value="Last 3 Months">Last 3 Months</option>
                    <option value="Last Month">Last Month</option>
                    <option value="This Month">This Month</option>
                  </select>
                </div>
                {/* Search Input */}
                <input
                  type="text"
                  placeholder="Search by opportunity..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    padding: "8px",
                    marginBottom: "20px",
                    width: "100%",
                    boxSizing: "border-box",
                    fontSize: "14px",
                  }}
                />
                {/* Front End Table */}
                <OpportunitiesTable
                  title="Front End Opportunities"
                  data={frontEndData}
                  isFrontEnd={true}
                />
                {/* Back End - Up Sell Table */}
                <OpportunitiesTable
                  title="Back End - Up Sell Opportunities"
                  data={backEndUpSellData}
                  isFrontEnd={false}
                />
                {/* Back End - Pricing Update Table */}
                <OpportunitiesTable
                  title="Back End - Pricing Update Opportunities"
                  data={backEndPricingUpdateData}
                  isFrontEnd={false}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <style jsx>{`
        .content {
          padding: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 10px 0;
        }
        th,
        td {
          border: 1px solid #ddd;
          text-align: left;
          padding: 8px !important; /* Added !important for padding */
          vertical-align: top;
          font-size: 12px;
        }
        th {
          background-color: #f4f4f4 !important; /* Added !important */
          font-weight: bold;
          position: sticky;
          top: 0;
          z-index: 1;
        }
        .totals-row td {
          background-color: #e9ecef !important; /* Light gray background with !important */
          font-weight: bold;
        }
        .category-totals td {
          background-color: #f8f9fa !important; /* Slightly lighter gray with !important */
          font-weight: bold;
        }
        ul {
          list-style-type: none;
          padding-left: 0;
          margin: 0;
        }
        li {
          margin-bottom: 4px;
        }
        .mi-warning {
          color: #dc3545 !important; /* Red color */
          margin-left: 5px;
        }
        .mi-success {
          color: #28a745 !important; /* Green color */
          margin-left: 5px;
        }
        .mi-attention {
          color: #ffc107 !important; /* Yellow color */
          margin-left: 5px;
        }
        .mi-different-amount {
          background-color: rgba(
            220,
            53,
            69,
            0.2
          ) !important; /* More pronounced red background with !important */
          padding: 2px 4px;
          border-radius: 4px;
        }
        .mi-highlight {
          background-color: rgba(
            40,
            167,
            69,
            0.2
          ) !important; /* More pronounced green background with !important */
          padding: 2px 4px;
          border-radius: 4px;
        }
        .highlight-month {
          background-color: rgba(
            0,
            123,
            255,
            0.08
          ) !important; /* More pronounced light blue background for the month column with !important */
        }
        .row-success {
          background-color: rgba(
            40,
            167,
            69,
            0.1
          ) !important; /* Slightly more pronounced green background with !important */
        }
        .row-error {
          background-color: rgba(
            220,
            53,
            69,
            0.2
          ) !important; /* More pronounced red background with !important */
        }
        .row-attention {
          background-color: rgba(
            255,
            193,
            7,
            0.2
          ) !important; /* Light yellow background with !important */
        }
        .general-totals {
          margin-bottom: 30px;
        }
        .general-totals table {
          border: 2px solid #dee2e6 !important; /* Thicker border for emphasis with !important */
        }
        .category-totals td {
          background-color: #f8f9fa !important; /* Slightly lighter gray with !important */
          font-weight: bold;
        }
        .notes-cell {
          white-space: nowrap;
        }
        h1 {
          margin-bottom: 20px;
        }
        h2 {
          margin-top: 40px;
          margin-bottom: 10px;
        }

        @media (max-width: 768px) {
          table {
            font-size: 10px;
          }

          th,
          td {
            padding: 6px !important; /* Added !important */
          }

          h2 {
            font-size: 18px;
          }
        }

        @media (max-width: 480px) {
          table {
            font-size: 9px;
          }

          th,
          td {
            padding: 4px !important; /* Added !important */
          }

          h1 {
            font-size: 20px;
          }

          h2 {
            font-size: 16px;
          }

          .content {
            padding: 10px;
          }
        }

        /* Styling for Notes Badges */
        .note-badge {
          display: inline-block;
          padding: 2px 6px;
          border-radius: 4px;
          font-size: 10px;
          margin-right: 4px;
          color: #fff;
        }
        .note-proration {
          background-color: #ffc107; /* Amber */
        }
        .note-temporary-discount {
          background-color: #17a2b8; /* Teal */
        }
        .note-delayed-billing {
          background-color: #dc3545; /* Red */
        }
      `}</style>
    </>
  );
};

export default SalesLifecycleDashboard;
