import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import ErrorMessage from "../../UI/ErrorMessage";

import { query } from "../../API";

import SelectAccount from "../selectAccount";

const RecordsOverview = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let { owId, sidebar } = useParams();
  const context = useContext(UserContext);

  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(`${currentYear}-01-01T00:00:00.000Z`);
  const startOfMonth = new Date(
    `${currentYear}-${new Date().getMonth() + 1}-01T00:00:00.000Z`
  );
  const startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  const startOfLastWeek = new Date(startOfWeek);
  startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);

  useEffect(() => {
    import("../../../Public.css");

    return () => {
      // Optionally, remove styles when the component unmounts
      const styleElement = document.querySelector(
        `link[href*="Public.css"], style[data-href*="Public.css"]`
      );
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);

  const loadPatients = async (id) => {
    try {
      setIsLoading(true);
      setData(null);
      if (id) {
        const filters = [
          { range: "This Week", start: startOfWeek },
          { range: "Last Week", start: startOfLastWeek, end: startOfWeek },
          { range: "This Month", start: startOfMonth },
          { range: "This Year", start: startOfYear },
        ];
        const patientData = {};
        for (const { range, start, end } of filters) {
          const result = await query(
            [
              {
                collectionName: "pmsPatients",
                filter: {
                  "location_ids.0": Number(id),
                  created_at: {
                    $gte: start,
                    ...(end && { $lt: end }),
                  },
                },
              },
            ],
            context
          );
          patientData[range] = result.pmsPatients.length;
        }
        setData(patientData);
        setError(false);
      }
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (context.accessToken === null) return <></>;

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {!sidebar && <Sidebar />}
          <div className={owId ? "contentWOS dashboard" : "content dashboard"}>
            <div
              className="chart-container"
              style={{
                gridTemplateColumns: "24.5% 24.5% 24.5% 24.5%",
              }}
            >
              <div className="cs-title">
                <SelectAccount callbackFunction={loadPatients} />
                <br />
                <h2>Patient Overview</h2>
              </div>
              <div
                className="card-container"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gap: "1rem",
                }}
              >
                {data && !isLoading && (
                  <>
                    <div className="chart-col chart-col-33 stat-card">
                      <h2>This Week</h2>
                      <h1>{data["This Week"] || 0}</h1>
                    </div>
                    <div className="chart-col chart-col-33 stat-card">
                      <h2>Last Week</h2>
                      <h1>{data["Last Week"] || 0}</h1>
                    </div>
                    <div className="chart-col chart-col-33 stat-card">
                      <h2>This Month</h2>
                      <h1>{data["This Month"] || 0}</h1>
                    </div>
                    <div className="chart-col chart-col-33 stat-card">
                      <h2>This Year</h2>
                      <h1>{data["This Year"] || 0}</h1>
                    </div>
                  </>
                )}
                {!data && !isLoading && (
                  <p>Select an account to view the data.</p>
                )}
                {isLoading && <p>Loading...</p>}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecordsOverview;
