import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRobot } from "@fortawesome/free-solid-svg-icons";
import ChatWidget from "./ChatWidget";

const RightPanel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPanel = () => setIsOpen(true);
  const closePanel = () => setIsOpen(false);

  return (
    <div className={`right-panel ${isOpen ? "open" : ""}`}>
      {/* Button to open the right panel */}
      <button className="open-right-panel-button" onClick={openPanel}>
        <FontAwesomeIcon icon={faRobot} />
      </button>

      <button className="right-panel-close" onClick={closePanel}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="right-panel-content">
        <ChatWidget />
      </div>
    </div>
  );
};

export default RightPanel;
