import React, { useEffect, useState } from "react";
import logo from "../../../logo.png"; // Replace with your logo image path

const loadingMessages = ["Loading", "Almost there", "Finalizing"];

const Loader = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [dotCount, setDotCount] = useState(0);

  useEffect(() => {
    // Change loading message every 3 seconds
    const messageInterval = setInterval(() => {
      setMessageIndex((prev) =>
        prev < loadingMessages.length - 1 ? prev + 1 : prev
      );
    }, 3000);

    return () => clearInterval(messageInterval);
  }, []);

  useEffect(() => {
    // Toggle visibility for fade effect every 1.5 seconds
    const fadeInterval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1500);

    return () => clearInterval(fadeInterval);
  }, []);

  useEffect(() => {
    // Animate dots (1 → 2 → 3 → repeat)
    const dotInterval = setInterval(() => {
      setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
    }, 500);

    return () => clearInterval(dotInterval);
  }, []);

  return (
    <div className="loading-screen">
      <img src={logo} alt="Logo" className="loading-logo" />
      <div
        className="loading-text"
        style={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 1.5s ease-in-out",
        }}
      >
        {loadingMessages[messageIndex]}
        {".".repeat(dotCount)}
      </div>
    </div>
  );
};

export default Loader;
