import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../UI/Sidebar";
import { useParams } from "react-router-dom";
import { UserContext } from "../../App";
import Loader from "../UI/Loader";
import ErrorMessage from "../UI/ErrorMessage";
import { getMinStats } from "../UI/SFData";
import StatsThisLastMonth from "../INTERNAL_DASHBOARDS/SalesStats/StatsThisLastMonth";
import Stats from "../INTERNAL_DASHBOARDS/SalesStats/Stats";
import StatsLastMonth from "../INTERNAL_DASHBOARDS/SalesStats/StatsLastMonth";
import StatsThisQuarter from "../INTERNAL_DASHBOARDS/SalesStats/StatsThisQuarter";
import StatsLastQuarter from "../INTERNAL_DASHBOARDS/SalesStats/StatsLastQuarter";

const SalesStats = () => {
  let { sidebar } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [view, setView] = useState("ThisMonth");

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getMinStats(context)
        .then(function (result) {
          refreshData(result);
        })
        .finally(() => (context.isLoading = false));
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  try {
    window["bodyScroll"]();
  } catch (err) {
    // Do nothing
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {sidebar ? <></> : <Sidebar />}
          {!data && <Loader />}
          {data && (
            <>
              <div
                className={"content dashboard"}
                style={{
                  background: "white",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    textAlign: "center",
                    background: "rgb(30, 115, 190)",
                    marginTop: "-3px",
                  }}
                >
                  <h6
                    id="general-stats"
                    style={{ marginBottom: "0px", color: "white" }}
                  >
                    {new Date().toJSON().slice(0, 10)}{" "}
                    <span
                      style={{ width: "20px", display: "inline-block" }}
                    ></span>{" "}
                    Active Memberships: {data.memberships}{" "}
                    <span
                      style={{ width: "20px", display: "inline-block" }}
                    ></span>{" "}
                    Overdue Invoices: {data.overdueInvoices}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  <button
                    onClick={(e) => {
                      setView("ThisLastMonth");
                    }}
                    className={`btn stats-btn ${
                      view === "ThisLastMonth" ? "btn-primary" : ""
                    }`}
                  >
                    This & Last Month
                  </button>
                  <button
                    onClick={(e) => {
                      setView("ThisMonth");
                    }}
                    className={`btn stats-btn ${
                      view === "ThisMonth" ? "btn-primary" : ""
                    }`}
                  >
                    This Month
                  </button>
                  <button
                    onClick={(e) => {
                      setView("LastMonth");
                    }}
                    className={`btn stats-btn ${
                      view === "LastMonth" ? "btn-primary" : ""
                    }`}
                  >
                    Last Month
                  </button>
                  <button
                    onClick={(e) => {
                      setView("ThisQT");
                    }}
                    className={`btn stats-btn ${
                      view === "ThisQT" ? "btn-primary" : ""
                    }`}
                  >
                    This Quarter
                  </button>
                  <button
                    onClick={(e) => {
                      setView("LastQT");
                    }}
                    className={`btn stats-btn ${
                      view === "LastQT" ? "btn-primary" : ""
                    }`}
                  >
                    Last Quarter
                  </button>
                </div>
                {view === "ThisLastMonth" && <StatsThisLastMonth />}
                {view === "ThisMonth" && <Stats />}
                {view === "LastMonth" && <StatsLastMonth />}
                {view === "ThisQT" && <StatsThisQuarter />}
                {view === "LastQT" && <StatsLastQuarter />}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SalesStats;
