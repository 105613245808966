import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../App";

import Sidebar from "../../UI/Sidebar";
import { getWebsiteDashboard } from "../../SF";
import {
  blueColor,
  DMPieChart,
  DMStackedChart,
  grayBorderColor,
  greenColor,
  multicolor,
  multicolor2,
  orangeColor,
  redColor,
  yellowColor,
} from "../../UI/DMCharts";
import Loader from "../../UI/Loader";
import ErrorMessage from "../../UI/ErrorMessage";

import OpenTasksTable from "../../UI/OpenTasksTable";

import { _groupTasks, _stagingSiteUrgency } from "../../Utils/CSInit";

import { openURLInNewTab } from "../../Utils";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";

const WebsiteDashboard = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  let { vpId, sidebar } = useParams();

  const openTasks = [];
  const overdueTasks = [];

  const grace = _stagingSiteUrgency();
  const ellie = _stagingSiteUrgency();
  const dallas = _stagingSiteUrgency();
  const unnassigned = _stagingSiteUrgency();
  const perType = {
    labels: [
      "Current Client Redesign",
      "New Client Redesign",
      "Migration",
      "New Build",
      "Other",
      "-",
    ],
    datasets: [
      {
        label: "Risk of Cancellation",
        data: [],
        backgroundColor: [
          multicolor,
          yellowColor,
          multicolor2,
          greenColor,
          blueColor,
          grayBorderColor,
        ],
      },
    ],
  };

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getWebsiteDashboard(context)
        .then(function (result) {
          refreshData(result);
        })
        .finally(() => (context.isLoading = false));
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  let totalFire = 0;
  let totalUrgent = 0;

  if (data) {
    grace.datasets[0].data.push(0);
    grace.datasets[1].data.push(0);
    grace.datasets[2].data.push(0);
    grace.datasets[3].data.push(0);
    grace.datasets[4].data.push(0);
    grace.datasets[5].data.push(0);

    ellie.datasets[0].data.push(0);
    ellie.datasets[1].data.push(0);
    ellie.datasets[2].data.push(0);
    ellie.datasets[3].data.push(0);
    ellie.datasets[4].data.push(0);
    ellie.datasets[5].data.push(0);

    dallas.datasets[0].data.push(0);
    dallas.datasets[1].data.push(0);
    dallas.datasets[2].data.push(0);
    dallas.datasets[3].data.push(0);
    dallas.datasets[4].data.push(0);
    dallas.datasets[5].data.push(0);

    unnassigned.datasets[0].data.push(0);
    unnassigned.datasets[1].data.push(0);
    unnassigned.datasets[2].data.push(0);
    unnassigned.datasets[3].data.push(0);
    unnassigned.datasets[4].data.push(0);
    unnassigned.datasets[5].data.push(0);

    perType.datasets[0].data.push(0);
    perType.datasets[0].data.push(0);
    perType.datasets[0].data.push(0);
    perType.datasets[0].data.push(0);
    perType.datasets[0].data.push(0);
    perType.datasets[0].data.push(0);

    grace.datasets[0].backgroundColor = greenColor;
    ellie.datasets[0].backgroundColor = greenColor;
    dallas.datasets[0].backgroundColor = greenColor;
    unnassigned.datasets[0].backgroundColor = greenColor;

    grace.datasets[1].backgroundColor = yellowColor;
    ellie.datasets[1].backgroundColor = yellowColor;
    dallas.datasets[1].backgroundColor = yellowColor;
    unnassigned.datasets[1].backgroundColor = yellowColor;

    grace.datasets[2].backgroundColor = orangeColor;
    ellie.datasets[2].backgroundColor = orangeColor;
    dallas.datasets[2].backgroundColor = orangeColor;
    unnassigned.datasets[2].backgroundColor = orangeColor;

    grace.datasets[3].backgroundColor = redColor;
    ellie.datasets[3].backgroundColor = redColor;
    dallas.datasets[3].backgroundColor = redColor;
    unnassigned.datasets[3].backgroundColor = redColor;

    grace.datasets[4].backgroundColor = blueColor;
    ellie.datasets[4].backgroundColor = blueColor;
    dallas.datasets[4].backgroundColor = blueColor;
    unnassigned.datasets[4].backgroundColor = blueColor;

    grace.datasets[5].backgroundColor = grayBorderColor;
    ellie.datasets[5].backgroundColor = grayBorderColor;
    dallas.datasets[5].backgroundColor = grayBorderColor;
    unnassigned.datasets[5].backgroundColor = grayBorderColor;

    console.log(data);
    let dallasCount = 0;
    if (data.StagingSites["Dallas Wise"]) {
      for (const staging of data.StagingSites["Dallas Wise"]) {
        dallasCount++;
        if (!staging.Staging_Site_Urgency__c)
          dallas.datasets[5].data[0] = dallas.datasets[5].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Onboarding")
          dallas.datasets[0].data[0] = dallas.datasets[0].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "In Progress")
          dallas.datasets[1].data[0] = dallas.datasets[1].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Fire") {
          dallas.datasets[2].data[0] = dallas.datasets[2].data[0] + 1;
          totalFire++;
        } else if (staging.Staging_Site_Urgency__c === "URGENT") {
          dallas.datasets[3].data[0] = dallas.datasets[3].data[0] + 1;
          totalUrgent++;
        } else if (staging.Staging_Site_Urgency__c === "Final Stages")
          dallas.datasets[4].data[0] = dallas.datasets[4].data[0] + 1;

        if (
          !staging.Website_Information__r ||
          !staging.Website_Information__r.Type__c
        )
          perType.datasets[0].data[5] = perType.datasets[0].data[5] + 1;
        else if (
          staging.Website_Information__r.Type__c === "Current Client Redesign"
        )
          perType.datasets[0].data[0] = perType.datasets[0].data[0] + 1;
        else if (
          staging.Website_Information__r.Type__c === "New Client Redesign"
        )
          perType.datasets[0].data[1] = perType.datasets[0].data[1] + 1;
        else if (staging.Website_Information__r.Type__c === "Migration")
          perType.datasets[0].data[2] = perType.datasets[0].data[2] + 1;
        else if (staging.Website_Information__r.Type__c === "New Build")
          perType.datasets[0].data[3] = perType.datasets[0].data[3] + 1;
        else if (staging.Website_Information__r.Type__c === "Other")
          perType.datasets[0].data[4] = perType.datasets[0].data[4] + 1;
      }
    }
    dallas.labels[0] = dallasCount;

    let ellieCount = 0;
    if (data.StagingSites["Ellie Hart"]) {
      for (const staging of data.StagingSites["Ellie Hart"]) {
        ellieCount++;
        if (!staging.Staging_Site_Urgency__c)
          ellie.datasets[5].data[0] = ellie.datasets[5].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Onboarding")
          ellie.datasets[0].data[0] = ellie.datasets[0].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "In Progress")
          ellie.datasets[1].data[0] = ellie.datasets[1].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Fire") {
          ellie.datasets[2].data[0] = ellie.datasets[2].data[0] + 1;
          totalFire++;
        } else if (staging.Staging_Site_Urgency__c === "URGENT") {
          ellie.datasets[3].data[0] = ellie.datasets[3].data[0] + 1;
          totalUrgent++;
        } else if (staging.Staging_Site_Urgency__c === "Final Stages")
          ellie.datasets[4].data[0] = ellie.datasets[4].data[0] + 1;

        if (
          !staging.Website_Information__r ||
          !staging.Website_Information__r.Type__c
        )
          perType.datasets[0].data[5] = perType.datasets[0].data[5] + 1;
        else if (
          staging.Website_Information__r.Type__c === "Current Client Redesign"
        )
          perType.datasets[0].data[0] = perType.datasets[0].data[0] + 1;
        else if (
          staging.Website_Information__r.Type__c === "New Client Redesign"
        )
          perType.datasets[0].data[1] = perType.datasets[0].data[1] + 1;
        else if (staging.Website_Information__r.Type__c === "Migration")
          perType.datasets[0].data[2] = perType.datasets[0].data[2] + 1;
        else if (staging.Website_Information__r.Type__c === "New Build")
          perType.datasets[0].data[3] = perType.datasets[0].data[3] + 1;
        else if (staging.Website_Information__r.Type__c === "Other")
          perType.datasets[0].data[4] = perType.datasets[0].data[4] + 1;
      }
    }
    ellie.labels[0] = ellieCount;

    let graceCount = 0;
    if (data.StagingSites["Grace Ethington"]) {
      for (const staging of data.StagingSites["Grace Ethington"]) {
        graceCount++;
        if (!staging.Staging_Site_Urgency__c)
          grace.datasets[5].data[0] = grace.datasets[5].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Onboarding")
          grace.datasets[0].data[0] = grace.datasets[0].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "In Progress")
          grace.datasets[1].data[0] = grace.datasets[1].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Fire") {
          grace.datasets[2].data[0] = grace.datasets[2].data[0] + 1;
          totalFire++;
        } else if (staging.Staging_Site_Urgency__c === "URGENT") {
          grace.datasets[3].data[0] = grace.datasets[3].data[0] + 1;
          totalUrgent++;
        } else if (staging.Staging_Site_Urgency__c === "Final Stages")
          grace.datasets[4].data[0] = grace.datasets[4].data[0] + 1;

        if (
          !staging.Website_Information__r ||
          !staging.Website_Information__r.Type__c
        )
          perType.datasets[0].data[5] = perType.datasets[0].data[5] + 1;
        else if (
          staging.Website_Information__r.Type__c === "Current Client Redesign"
        )
          perType.datasets[0].data[0] = perType.datasets[0].data[0] + 1;
        else if (
          staging.Website_Information__r.Type__c === "New Client Redesign"
        )
          perType.datasets[0].data[1] = perType.datasets[0].data[1] + 1;
        else if (staging.Website_Information__r.Type__c === "Migration")
          perType.datasets[0].data[2] = perType.datasets[0].data[2] + 1;
        else if (staging.Website_Information__r.Type__c === "New Build")
          perType.datasets[0].data[3] = perType.datasets[0].data[3] + 1;
        else if (staging.Website_Information__r.Type__c === "Other")
          perType.datasets[0].data[4] = perType.datasets[0].data[4] + 1;
      }
    }
    grace.labels[0] = graceCount;

    let unassignedCount = 0;
    if (data.StagingSites["Unassigned"]) {
      for (const staging of data.StagingSites["Unassigned"]) {
        unassignedCount++;
        if (!staging.Staging_Site_Urgency__c)
          unnassigned.datasets[5].data[0] = unnassigned.datasets[5].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Onboarding")
          unnassigned.datasets[0].data[0] = unnassigned.datasets[0].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "In Progress")
          unnassigned.datasets[1].data[0] = unnassigned.datasets[1].data[0] + 1;
        else if (staging.Staging_Site_Urgency__c === "Fire") {
          unnassigned.datasets[2].data[0] = unnassigned.datasets[2].data[0] + 1;
          totalFire++;
        } else if (staging.Staging_Site_Urgency__c === "URGENT") {
          unnassigned.datasets[3].data[0] = unnassigned.datasets[3].data[0] + 1;
          totalUrgent++;
        } else if (staging.Staging_Site_Urgency__c === "Final Stages")
          unnassigned.datasets[4].data[0] = unnassigned.datasets[4].data[0] + 1;

        if (
          !staging.Website_Information__r ||
          !staging.Website_Information__r.Type__c
        )
          perType.datasets[0].data[5] = perType.datasets[0].data[5] + 1;
        else if (
          staging.Website_Information__r.Type__c === "Current Client Redesign"
        )
          perType.datasets[0].data[0] = perType.datasets[0].data[0] + 1;
        else if (
          staging.Website_Information__r.Type__c === "New Client Redesign"
        )
          perType.datasets[0].data[1] = perType.datasets[0].data[1] + 1;
        else if (staging.Website_Information__r.Type__c === "Migration")
          perType.datasets[0].data[2] = perType.datasets[0].data[2] + 1;
        else if (staging.Website_Information__r.Type__c === "New Build")
          perType.datasets[0].data[3] = perType.datasets[0].data[3] + 1;
        else if (staging.Website_Information__r.Type__c === "Other")
          perType.datasets[0].data[4] = perType.datasets[0].data[4] + 1;
      }
    }
    unnassigned.labels[0] = unassignedCount;

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);
  }

  try {
    window["bodyScroll"]();
  } catch (err) {
    // Do nothing
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          {sidebar ? <></> : <Sidebar />}
          <div
            className={vpId ? "contentWOS dashboard" : "content dashboard"}
            style={{
              background: "#EAEAEA",
              padding: "20px",
              paddingLeft: "40px",
            }}
          >
            {!data && <Loader />}
            {data && (
              <>
                <div
                  style={{
                    height: "100%",
                    minHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "25% 25% 25% 24%",
                      marginTop: "20px",
                    }}
                  >
                    <div className="cs-title">
                      {"All Users"}
                      <p className="cs-legend">
                        <span
                          className="square"
                          style={{ background: greenColor }}
                        ></span>
                        <span>Completed / On Time</span>
                        <span
                          className="square"
                          style={{ background: yellowColor }}
                        ></span>
                        <span>Needs Attention</span>
                        <span
                          className="square"
                          style={{ background: redColor }}
                        ></span>
                        <span>Urgent / Overdue</span>
                        <span
                          className="square"
                          style={{ background: orangeColor }}
                        ></span>
                        <span>Out of My Control</span>
                      </p>
                    </div>
                  </div>

                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "25% 25% 25% 24%",
                      marginTop: "5px",
                    }}
                  >
                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KX0Z2AW/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Grace's Queue"
                        data={grace}
                        darkFont={darkFont}
                        toolTip={"Staging Sites Assigned to Grace"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/sObject/00OEY000000KX3i2AG/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Dallas's Queue"
                        data={dallas}
                        darkFont={darkFont}
                        toolTip={"Staging Sites Assigned to Dallas"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/sObject/00OEY000000KX3s2AG/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Ellis's Queue"
                        data={ellie}
                        darkFont={darkFont}
                        toolTip={"Staging Sites Assigned to Ellie"}
                      />
                    </div>

                    <div
                      className="chart-col chart-col-33"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/sObject/00OEY000000KX3x2AG/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Unassigned Queue"
                        data={unnassigned}
                        darkFont={darkFont}
                        toolTip={"Staging Sites Unassigned"}
                      />
                    </div>
                  </div>
                  <div
                    className="chart-container chart-col-100"
                    style={{
                      gridTemplateColumns: "25% 25% 49%",
                      marginTop: "5px",
                    }}
                  >
                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background: orangeColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KX6N2AW/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <span data-tip data-for={"onfire"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"onfire"}>
                          {"Number of Staging Sites On Fire"}
                        </ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "30px",
                          color: "white",
                        }}
                      >
                        On Fire
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "50px",
                        }}
                      >
                        #{totalFire}
                      </h1>
                    </div>

                    <div
                      className="chart-col chart-col-33 stat-card"
                      style={{
                        cursor: "pointer",
                        background: redColor,
                        color: "white",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KX6S2AW/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <span data-tip data-for={"urgent"}>
                        <FontAwesomeIcon
                          style={{ fontSize: "25px" }}
                          icon={faInfoCircle}
                        />
                        <ReactTooltip id={"urgent"}>{"URGENT!!"}</ReactTooltip>
                      </span>
                      <h2
                        style={{
                          marginTop: "30px",
                          color: "white",
                        }}
                      >
                        URGENT
                      </h2>
                      <h1
                        style={{
                          color: "white",
                          marginBottom: "50px",
                        }}
                      >
                        #{totalUrgent}
                      </h1>
                    </div>

                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargleinc.lightning.force.com/lightning/r/Report/00OEY000000KX6h2AG/view?queryScope=userFolders"
                        );
                      }}
                    >
                      <DMPieChart
                        subtitle="Queue Per Type"
                        data={perType}
                        darkFont={darkFont}
                        toolTip={"Staging Sites Queue Per Type"}
                        height="300px"
                      />
                    </div>
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default WebsiteDashboard;
